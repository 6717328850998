import React from "react";
import { useState, useRef, useEffect, useMemo, useCallback,useContext } from "react";
import { Eye } from 'react-bootstrap-icons';
import toast, { Toaster } from "react-hot-toast";
import { fetchFileDetails } from "../Services/UserServices";

import { AgGridReact } from 'ag-grid-react';
import Accounttemplatestyle from '../Template/Addtemplate.module.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import CustomNoRowsOverlay from './customNoRowsOverlayreject';
const ShowDQMReject = ({ showtem, setshowDQM, dqmdata, type }) => {
  const gridRef = useRef();
  //const [show, showDQMtype] = useState(false);
  const [rowDatasumarry, setRowData] = useState([])
  const [errormessage, setErrormessage] = useState("");
  const [show, setShow] = useState(false);
  const [failedrow, setFailedRow] = useState(false);
  const [headerContent, setheaderContent] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [headerContentDetail, setheaderContentDetail] = useState([]);
  const [rowDatasumarryDetail, setRowDataDetail] = useState([])
  const [faileddata, setFailedData] = useState([])
  useEffect(() => {

    setShow(showtem);
  }, [showtem])


  const handleClose = (e) => {
    //setShow(false);
    //console.log(showtem)
    setshowDQM(prev => !prev)

  };
  const handleCloseError = (e) => {
    //setShow(false);
    //console.log(showtem)
    setFailedRow(prev => !prev)

  };
  let onSelect = (index) => {
    //alert(index)
    setTabIndex(index)
    document.getElementById('filter-text-box1').value = ''

  }

  //console.log(dqmdata['requestId'])
  const onGridReady = useCallback((params) => {
   // console.log(dqmdata)
    fetchFileData(dqmdata['action_id'].toString())
  }, []);

  const fetchFileData = (action_id) => {
    //console.log(type)

    fetchFileDetails(action_id, 'DQM').then((datas) => {

      console.log(datas)
      if ('result' in datas['data'] && datas['data']?.status === "success") {
        if (datas['data']?.['result']['summary']['headerContent'].length > 0) {

          const headerContent = datas['data']?.['result']['summary']['headerContent'].map((header) => {
            const cleanedHeader = header.replace(/\./g, '');
            return { headerName: cleanedHeader, field: cleanedHeader, tooltipField: header };
          });

          setheaderContent(headerContent)
          //console.log(headerContent)

          const headers = datas['data']?.['result']['summary']['headerContent'];

          const bodyContentsummary = datas['data']?.['result']['summary']['bodyContent'].map(row => {
            let obj = {};
            row.forEach((item, index) => {
              obj[headers[index]] = item;
            });
            return obj;
          });

          ////console.log(bodyContentsummary);
          setRowData(bodyContentsummary);

        }
        if (datas['data']?.['result']['detail']['headerContent'].length > 0) {

          const headerContentdetail = datas['data']?.['result']['detail']['headerContent'].map((header) => {
            const cleanedHeader = header.replace(/\./g, '');
            if (header === 'Row Viewer') {
              return {
                headerName: header, field: header, tooltipField: header, width: 70, maxWidth: 120, cellRenderer: function (params) {
                  return (<>
                    <span style={{ cursor: 'pointer' }} data-toggle="tooltip" onClick={() => viewDetailData(params)} data-action-type="view" data-placement="top" title="View Failed Row" ><Eye size={20} data-action-type="view" /></span>
                  </>)
                }
              };
            }
            else {
              return { headerName: cleanedHeader, field: cleanedHeader, tooltipField: header };
            }
          });

          setheaderContentDetail(headerContentdetail)
          //console.log(headerContentdetail)

          const headers = datas['data']?.['result']['detail']['headerContent'];

          const bodyContentdetail = datas['data']?.['result']['detail']['bodyContent'].map(row => {
            let obj = {};
            row.forEach((item, index) => {
              obj[headers[index]] = item;
            });
            return obj;
          });

          //console.log(bodyContentdetail);
          setRowDataDetail(bodyContentdetail);

        }
      }
      else {
        setErrormessage(datas['data']?.message)
        //toast.error(datas?.message)
      }

    })
      .catch(error => {
        //setErrormessage(error.toString())
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 401) {
            console.log("initSSO: Token invalid - let's try again,token epired");
            window.localStorage.removeItem("hashToken");
            window.localStorage.removeItem("accessToken");
            window.open(window.location.origin + "/api/link", "_self");
          }

          else if (error.response.status === 403) {
            toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')

          }
          else {
           
            setErrormessage(error.toString());

          }

        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')

        }
        //toast.error(error.toString())
        //console.error('There was an error!', error);

      });



  }

  const viewDetailData = (params) => {
    // console.log(params)
    // console.log(params.data['Row Viewer'])
    const failedRow = params.data['Row Viewer']
    let failedRowarr = failedRow.split("|");
   // console.log(failedRowarr);
    let failedRowData = [];
    failedRowarr.forEach((item, index) => {
      let row = item.split("=");
      failedRowData.push({ tab_name: row[0], column_name: row[1] });
    })
    //console.log(failedRowData);
    setFailedData(failedRowData)
    setFailedRow(true)
  }

  //const [columnDefs] = useState(headerContent)
  const [columnDefs] = useState([
    { headerName: 'Tab Name', field: 'tab_name' },
    { headerName: 'Column Name', field: 'column_name', tooltipField: 'column_name' },
  ])



  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => 'No rows found at: ' + new Date().toLocaleTimeString(),
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      // minWidth: 100,
      // initialWidth: 200,
      // wrapHeaderText: true,
      // autoHeaderHeight: true,
      // width: 170,
      // resizable: true,
      // wrapText: true,
      // autoHeight: true,
      cacheQuickFilter: true,
      flex: 1,

      sortable: true,
      color: "red",

      wrapHeaderText: true,
      suppressMovable: true,
      autoHeaderHeight: true,
      //     wrapText: true,
      //    autoHeight: true,
      unSortIcon: true,
    };
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    // gridOptions.api.setQuickFilter(
    //   document.getElementById('filter-text-box1').value
    // );
    gridRef.current.api.setQuickFilter(
      document.getElementById('filter-text-box1').value
    );
    // Check if there are any rows after applying the filter
    const filteredRowCount = gridRef.current.api.getDisplayedRowCount();
    // If there are no rows, show the no rows overlay
    if (filteredRowCount === 0) {
      gridRef.current.api.showNoRowsOverlay();
    } else {
      gridRef.current.api.hideOverlay();
    }
  
}, []);

const resetSearch = () => {
  document.getElementById('filter-text-box1').value = ''
  onFilterTextBoxChanged()
}
return (
  <>

    {showtem ? (
      <div
        className={"modal fade bd-example-modal-lg" + (show ? " show d-block" : " d-none")}
        // tabIndex="-1"
        role="dialog"
        style={{
          position: "fixed",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          background: "rgb(182 184 202/0.5)",
          //opacity: "0.5",
          paddingTop: "4%",
          zIndex: 99999
        }}
      >
        <div className="modal-dialog modal-xl modal-dialog-recenterfile" role="document" >
          <div className="modal-content" style={{ opacity: "1" }}>
            <div className="">
              <h5 className="pl-2 modal-title" style={{ fontSize: '17px', wordBreak: 'break-word', backgroundColor: '#204056', color: '#fff' }}>
                {/* <span class="white smaller-70"><i class="fa fa-arrow-left"></i></span> */}
                Data Quality Report</h5>
            </div>
            <div className="modal-header pb-2">


              <div className="container-fluid">
                <div className="row">

                  <div className="col-md-8 col-lg-8 col-sm-8 pb-0" style={{ minHeight: 30 }}>
                    <h5 className="modal-title" style={{ fontSize: '14px', wordBreak: 'break-word' }}>File Name : {dqmdata?.['fileName']}</h5>
                    <h5 className="modal-title" style={{ fontSize: '14px', wordBreak: 'break-word' }}>Author Name : {dqmdata?.['author']}</h5>
                    <h5 className="modal-title" style={{ fontSize: '14px', wordBreak: 'break-word' }}>File Version : {dqmdata?.['file_version']}</h5>

                  </div>

                  <div className="col-md-4 col-lg-4 col-sm-8 pb-0 text-center" style={{ minHeight: 30 }}>


                    <div className="input-group searchBox" style={{ width: 'auto', border: 'transparent' }}>
                      <div className="input-group-prepend border-right-0">
                        <div
                          className="input-group-text bg-transparent "
                          id="btnGroupAddon2"
                        >
                          <i className="fa fa-search font-size-14"></i>
                        </div>
                      </div>
                      <input
                        className="form-control font-size-14 "
                        id="filter-text-box1"
                        type="search"
                        placeholder="Search..."
                        onInput={onFilterTextBoxChanged}
                      />
                      <button type="button" onClick={resetSearch} class="btn bg-transparent" style={{ marginLeft: '-40px', zIndex: 100 }} >
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div className="col-12">
                    {/* {errormessage &&
                        <span className={` ${Accounttemplatestyle.mandetoryfield}`}>{errormessage}</span>
                      } */}
                    {errormessage && (
                      <div class="alert alert-danger m-0" role="alert">
                        <i className="fa fa-exclamation-circle mr-2" style={{ color: 'red', fontSize: '19px' }}></i>{errormessage}
                      </div>
                    )}

                  </div>



                </div>
              </div>



              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true"><FontAwesomeIcon icon={faClose} size="lg" /></span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">

                <div className="col-12 pb-1 ">
                  <Tabs onSelect={onSelect} >
                    <TabList>
                      <Tab ><FontAwesomeIcon icon={faSquareCheck} size="sm" /><span className="tabspace">Data Quality Summary Report</span></Tab>
                      <Tab ><FontAwesomeIcon icon={faListUl} size="sm" /><span className="tabspace">Data Quality Detail Report</span></Tab>
                    </TabList>

                    <TabPanel>
                      <div   >
                        <div style={{ height: '100%', width: 'auto' }}>
                          <div
                            className="ag-theme-alpine datatable"
                            style={{
                              height: '385px',
                              width: 'auto'
                            }}
                          >
                            <AgGridReact

                              columnDefs={headerContent}
                              rowData={rowDatasumarry}
                              defaultColDef={defaultColDef}
                              animateRows={true}
                              onGridReady={onGridReady}
                              pagination={true}
                              //rowHeight={80}
                              paginationAutoPageSize={true}
                              cacheQuickFilter={true}
                              ref={gridRef}
                              noRowsOverlayComponent={CustomNoRowsOverlay}
                              noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                            >
                            </AgGridReact>
                          </div>


                        </div>

                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div   >
                        <div style={{ height: '100%', width: 'auto' }}>
                          <div
                            className="ag-theme-alpine datatable"
                            style={{
                              height: '430px',
                              width: 'auto'
                            }}
                          >
                            <AgGridReact

                              columnDefs={headerContentDetail}
                              rowData={rowDatasumarryDetail}
                              defaultColDef={defaultColDef}
                              animateRows={true}
                              onGridReady={onGridReady}
                              pagination={true}
                              //rowHeight={80}
                              paginationAutoPageSize={true}
                              cacheQuickFilter={true}
                              ref={gridRef}
                              noRowsOverlayComponent={CustomNoRowsOverlay}
                              noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                            >
                            </AgGridReact>
                          </div>


                        </div>

                      </div>
                    </TabPanel>
                  </Tabs>
                </div>


              </div>



              <Toaster
                position="top-right"
                reverseOrder={false}
                toastOptions={{
                  className: "",
                  duration: 5000,
                  error: {
                    icon: "❗",
                    style: {
                      color: 'red',
                    },
                  },
                  success: {
                    duration: 5000,
                    style: {
                      color: 'green',
                    },
                  },
                }}
              />



            </div>

          </div>
        </div>
      </div>


    ) : null}


    {failedrow ? (
      <div
        className={"modal fade bd-example-modal-lg" + (failedrow ? " show d-block" : " d-none")}
        // tabIndex="-1"
        role="dialog"
        style={{
          position: "fixed",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          background: "rgb(182 184 202/0.5)",
          //opacity: "0.5",
          paddingTop: "4%",
          zIndex: 99999
        }}
      >
        <div className="modal-dialog modal-xl modal-dialog-recenterfile" role="document" >
          <div className="modal-content" style={{ opacity: "1" }}>
            <div className="modal-header" style={{ backgroundColor: '#2E5378' }}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-8 pb-0" style={{ minHeight: 30 }}>

                    <h5 className="modal-title" style={{ fontSize: '16px', wordBreak: 'break-word', color: '#fff' }}>
                      {/* <span class="white smaller-70"><i class="fa fa-arrow-left"></i></span> */}
                      Row Viewer</h5>

                  </div>
                  <div className="col-4 pb-0 text-center" style={{ minHeight: 30 }}>


                    <div className="input-group searchBox" style={{ width: 'auto', border: 'transparent' }}>
                      <div className="input-group-prepend border-right-0">
                        <div
                          className="input-group-text bg-transparent "
                          id="btnGroupAddon2"
                        >
                          <i className="fa fa-search font-size-14" style={{ color: '#fff' }}></i>
                        </div>
                      </div>
                      <input
                        className="form-control font-size-14 "
                        id="filter-text-box1"
                        type="search"
                        placeholder="Search..."
                        onInput={onFilterTextBoxChanged}
                      />
                      <button type="button" onClick={resetSearch} class="btn bg-transparent" style={{ marginLeft: '-40px', zIndex: 100 }} >
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>



                </div>
              </div>


              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseError}
              >
                <span aria-hidden="true" ><FontAwesomeIcon icon={faClose} size="lg" style={{ color: '#fff' }} /></span>
              </button>
            </div>
            <div className="modal-body">

              <div style={{ height: '100%', width: 'auto' }}>
                <div
                  className="ag-theme-alpine datatable"
                  style={{
                    height: '450px',
                    width: 'auto'
                  }}
                >
                  <AgGridReact

                    columnDefs={columnDefs}
                    rowData={faileddata}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    onGridReady={onGridReady}
                    pagination={true}
                    //rowHeight={80}
                    paginationAutoPageSize={true}
                    cacheQuickFilter={true}
                    ref={gridRef}

                  >
                  </AgGridReact>
                </div>


              </div>


            </div>

          </div>

        </div>
      </div>

    ) : null}





  </>
)


}

export default ShowDQMReject;