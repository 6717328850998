import React, {
    useState,
    useContext,
    useEffect,
} from "react";
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ResponsiveContainer, LineChart, Line, Legend } from 'recharts';
import Tablefilterdata from "./tablefilterdata";
import {
    Archive,
    ArrowCounterclockwise,
    CheckLg,
    Download,
    Eye,
    Plus,
    Record,
    RecordFill,
    SquareFill,
    StarFill,
    SuitDiamondFill,
    TriangleFill,
    Upload,
    X,
} from "react-bootstrap-icons";
import Accounttemplatestyle from "../Template/Addtemplate.module.css";


import Select from 'react-select';
import {
    submitBasicFilter, submitAdvanceFilter
} from "../Services/UserServices";
import Loader from '../Loader';
import { error } from "jquery";
import toast, { Toaster } from "react-hot-toast";
const data_sc = [
    { x: 100, y: 200, z: 200 },
    { x: 120, y: 100, z: 260 },
    { x: 170, y: 300, z: 400 },
    { x: 140, y: 250, z: 280 },
    { x: 150, y: 400, z: 500 },
    { x: 110, y: 280, z: 200 },
];
const SHAPE1 = ['star', 'circle', 'square', 'triangle', 'cross', 'diamond', 'star', 'circle', 'square', 'triangle', 'cross', 'diamond', 'star', 'circle', 'square', 'triangle', 'cross', 'diamond']
const COLORS1 = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink', '#FFC300', '#302F6C', '#33F0FF', '#FF334C', '#33FFA8', '#2F6C52'];

const SHAPE200 = ['star', 'circle', 'square', 'triangle', 'diamond'];
const SHAPE = [];

while (SHAPE.length < 200) {
    SHAPE.push(...SHAPE200);
}

// Trim the array to exactly 200 elements in case it exceeds
SHAPE.length = 200;

// console.log(SHAPE);
const COLORS200 = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink', '#FFC300', '#302F6C', '#33F0FF', '#FF334C'];
const COLORS = []
while (COLORS.length < 200) {
    COLORS.push(...COLORS200);
}

// Trim the array to exactly 200 elements in case it exceeds
COLORS.length = 200;

// console.log(COLORS);


const dataline = [

    // {
    //     "name": "1",
    //     "result_numeric": "12.0",
    //     "data_source": "1",
    //     "amt": "12.0"
    // },
    // {
    //     "name": "2",
    //     "result_numeric": "99.0",
    //     "data_source": "2",
    //     "amt": "99.0"
    // },
    // {
    //     "name": "3",
    //     "result_numeric": "66.0",
    //     "data_source": "3",
    //     "amt": "66.0"
    // }

    // {
    //     name: 'Page A',
    //     uv: 4000,
    //     pv: 2400,
    //     amt: 2400,
    // },
    // {
    //     name: 'Page B',
    //     uv: 3000,
    //     pv: 1398,
    //     amt: 2210,
    // },
    // {
    //     name: 'Page C',
    //     uv: 2000,
    //     pv: 9800,
    //     amt: 2290,
    // },
    // {
    //     name: 'Page D',
    //     uv: 2780,
    //     pv: 3908,
    //     amt: 2000,
    // },
    // {
    //     name: 'Page E',
    //     uv: 1890,
    //     pv: 4800,
    //     amt: 2181,
    // },
    // {
    //     name: 'Page F',
    //     uv: 2390,
    //     pv: 3800,
    //     amt: 2500,
    // },
    // {
    //     name: 'Page G',
    //     uv: 3490,
    //     pv: 4300,
    //     amt: 2100,
    // },
];

const Visualisation = (props) => {

    const [Clicked, setClicked] = useState(false);
    const [vizdata, setVizdata] = useState(false);
    const [options, setTemplateData] = useState([

        {
            value: 1,
            label: 'label1',
        },
        {
            value: 1,
            label: 'label2',
        },
        {
            value: 1,
            label: 'label3',
        },
        {
            value: 1,
            label: 'label4',
        },
        {
            value: 1,
            label: 'label5',
        },
        {
            value: 1,
            label: 'label6',
        },

    ]);
    const [vizbody, setVizbody] = useState([]);
    const [searchtextwithkeyarr, setsearchtextwithkeyarr] = useState([]);
    const [vizheaders, setVizheaders] = useState([]);
    const [initialheaders, setInitialheaders] = useState([]);
    const [vizfilterdata, setVizfilterdata] = useState([]);
    const [openAccordian, setOpenAccordian] = useState([]);
    const [selectedOptionval, setSelectedOptionval] = useState("");
    const [vizFilterbody, setVizFilterbody] = useState([]);
    const [xaxislist, setxaxislist] = useState([]);
    const [xaxisdefault, setxaxisdefault] = useState({});
    const [shapedefault, setshapedefault] = useState({});
    const [yaxisdefault, setyaxisdefault] = useState({});
    const [colordefault, setcolordefault] = useState({});
    const [yaxislist, setyaxislist] = useState([]);
    const [shapelist, setshapelist] = useState([]);
    const [colorlist, setcolorlist] = useState([]);
    const [colorwisecolrcode, setcolorwisecolrcode] = useState([]);
    const [shapewiseshape, setshapewiseshape] = useState([]);

    const [errormessage, setErrormessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState([]);
    const [selectedOptionxaxis, setSelectedOptionxaxis] = useState("");
    const [scatterChart, setScatterChart] = useState(false);
    const [scatterBlock, setscatterBlock] = useState(true);
    const [lineBlock, setlineBlock] = useState(false);
    const [lineChart, setLineChart] = useState(false);
    const [ApplyClick, setApplyClick] = useState(false);
    const [Graphdata, setGraphdata] = useState([]);
    const [lineData, setLineData] = useState([]);
    const [filterChartData, setFilterChartData] = useState([]);
    const [filterGraphDataFuture, setFilterGraphDataForFuture] = useState([]);
    const [GraphdataType, setGraphdataType] = useState('');
    const [gcolor, setColor] = useState('')
    const [initialheaderstable, setinitialheaderstable] = useState([])
    const [maxLength, setMaxLength] = useState(0)
    const [clickedPoint, setClickedPoint] = useState(null);
    // const [scatterData, setScatterData] = useState([{ x: 100, y: 200, z: 200 },
    // { x: 120, y: 100, z: 260 },
    // { x: 170, y: 300, z: 400 },
    // { x: 140, y: 250, z: 280 },
    // { x: 150, y: 400, z: 500 },
    // { x: 110, y: 280, z: 200 }]);
    const [scatterData, setScatterData] = useState([]);
    const [colorscatter, setcolorscatter] = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink', 'Orange', 'Blue', '#33F0FF', '#FF334C', '#33FFA8', '#2F6C52'];
    useEffect(() => {

        setVizdata(props?.['Advancefilterdata'])
        var data = props?.['Advancefilterdata']
        //console.log(data)
        console.log('kklklklk')
        if ('result' in data && data['status'] === 'success') {
           // console.log(data)
            var headers = data?.['result']['filterList'];

            var body = data?.['result']['filterValues'];
            var xaxis = data?.['result']['xAxis'];
            var yaxis = data?.['result']['yAxis'];
            var shape = data?.['result']['shapeBy'];
            var color = data?.['result']['colorBy'];
            let sortedObject = Object.entries(body).reduce((acc, [key, value]) => {
                //acc[key] = [...value].sort((a, b) => a.localeCompare(b));

                var uniqueArray = [...new Set(value)];
                acc[key] = uniqueArray.map(v => (v === ' ' || v === null) ? 'Empty' : v.toString());

                //acc[key] = uniqueArray.map(v => (v === ' ' || v === null) ? 'Empty' : v.toString()).sort((a, b) => a.localeCompare(b));
                return acc;
            }, {});
            //console.log(sortedObject)
            setVizbody(sortedObject)
            setVizFilterbody(sortedObject)

        }
        generateAdvanceFilter(body, headers)
        generateRightSide(xaxis, yaxis, shape, color)

    }, [])


    const generateRightSide = (xaxis, yaxis, shape, color) => {

        console.log('righttttttttttttt')
        let xaxislist = xaxis.map((item) => ({
            value: item.key,
            label: item.label
        }));
        //console.log(xaxislist)
        setxaxislist(xaxislist)
        setxaxisdefault(xaxislist[0])
        // var  arrxaxis = []
        // setSelectedOptionxaxis(arrxaxis.push(xaxislist[0])) 
        // console.log(arrxaxis)
        let yaxislist = yaxis.map((item) => ({
            value: item.key,
            label: item.label
        }));
        setyaxislist(yaxislist)
        setyaxisdefault(yaxislist[0])
        let shapelist = shape.map((item) => ({
            value: item.key,
            label: item.label
        }));

        setshapelist(shapelist)
        setshapedefault(shapelist[0])
        let colorlist = color.map((item) => ({
            value: item.key,
            label: item.label
        }));
        setcolorlist(colorlist)
        setcolordefault(colorlist[0])
        localStorage.setItem("xaxis", JSON.stringify(xaxislist[0]))
        localStorage.setItem("yaxis", JSON.stringify(yaxislist[0]))
        localStorage.setItem("shape", JSON.stringify(shapelist[0]))
        localStorage.setItem("color", JSON.stringify(colorlist[0]))

    }
    const generateAdvanceFilter = (body, headers) => {
        //console.log(body)

        let headerslist = headers.map((item) => ({
            value: item.key,
            label: item.label,
            show: '',
            delete: false,
            filtertext: '',
            selectedItem: []
        }));
        ////console.log(headerslist)
        setInitialheaders(headerslist)
        if (localStorage.getItem("restoreadvancefilter") !== null) {
            let restoreadvancefilter = JSON.parse(localStorage.getItem("restoreadvancefilter"));
            //console.log(restoreadvancefilter)
            setVizfilterdata(restoreadvancefilter)
            // setscatterBlock(true)
            // setScatterChart(true)
            // setApplyClick(true)
            // setClicked(true)
            const filteredArray = headerslist.filter(item => {
                // Find a matching entry in filterTexts
                const filterEntry = restoreadvancefilter.find(filter => filter.value === item.value);
                // Exclude the item if a matching entry is found
                return !filterEntry;

            });
            //console.log(filteredArray)
            setVizheaders(filteredArray)
            let xaxis = JSON.parse(localStorage.getItem("xaxis"))
            console.log('editttttttttttttttttttttttt')
            //console.log(xaxis)
            setxaxisdefault(xaxis)
            let yaxis = JSON.parse(localStorage.getItem("yaxis"))
            setyaxisdefault(yaxis)
            let shape = JSON.parse(localStorage.getItem("shape"))
            setshapedefault(shape)
            let color = JSON.parse(localStorage.getItem("color"))
            setcolordefault(color)
            filterbyCheck(restoreadvancefilter)
            var lastselectedchart = localStorage.getItem("lastselectedchart")
            if (lastselectedchart !== null) {
                if (lastselectedchart === 'scatter' || lastselectedchart === 'line') {
                    setApplyClick(true)
                    setClicked(true)
                    chartDataPopulation()
                }


            }

            //const filteredArray = headerslist.filter(item => {

        }
        else {
            setVizfilterdata([headerslist[0]])
            //setVizheaders(headerslist)
            setVizheaders(headerslist.filter(obj => obj !== headerslist[0]));
        }
        //setVizfilterdata([headerslist[0]])

        //console.log(headerslist)
        // console.log(headerslist[0])
        //setVizheaders(headerslist => headerslist.filter(obj => obj !== headerslist[0]));


    }

    const setSelectedOption = (e) => {
        // console.log(e)
        // console.log(vizfilterdata)
        const input = e.value;
        setSelectedOptionval(e)
        const output = { 0: [input] };
        //console.log(output); // Output: {material_des: ['material_des']}
        setOpenAccordian([e.value])
        const newArray = vizfilterdata.map(obj => ({ ...obj, show: '' }));
       // console.log(newArray)
        setVizfilterdata(newArray);
        const newObject = {
            value: e.value,
            label: e.label,
            show: 'show', delete: true, filtertext: '', selectedItem: []
        };

        //setVizfilterdata(prevState => [...prevState,e]);
        //setVizfilterdata(prevState => [e, ...prevState]);
        setVizfilterdata(prevState => [newObject, ...prevState]);
        //console.log(vizheaders)
        const objectToRemove = e;

        setVizheaders(prevState => prevState.filter(obj => obj !== objectToRemove));
        setSelectedOptionval(e)

    }

    const clickPencil = () => {
        setClicked(true)

    }
    const removeFilter = (header) => {
        //alert('kk')
        // console.log(header)
        // console.log(openAccordian)
        setVizfilterdata(prevState => prevState.filter(obj => obj !== header));
        setVizheaders(prevState => [header, ...prevState]);
    }

    // const resetFilter = () => {
    //     setVizfilterdata([])
    //     setVizheaders(initialheaders)
    //     setVizfilterdata([vizheaders[0]])
    //     setVizbody(vizFilterbody)
    //     //  setGraphdata([])
    //     //  setLineData([])
    //     // setScatterData([])
    //     //setApplyClick(false)
    //     //setLineChart(false)

    //     //setVizbody(vizFilterbody1)
    // }
    const resetFilter = () => {
        //console.log(vizFilterbody)
        //setVizfilterdata([])
        //setVizheaders(initialheaders)
        //console.log(initialheaders)
        setVizfilterdata([initialheaders[0]])
        setVizheaders(initialheaders.filter(obj => obj !== initialheaders[0]));
        localStorage.removeItem("lastselectedheader");
        localStorage.removeItem("lastselectedheaderdata");
        localStorage.removeItem("restoreadvancefilter");
        localStorage.removeItem("lastselectedchart");
        filterbyCheck([])
        //setVizbody(vizFilterbody)
        setGraphdata([])
        setLineData([])
        setScatterData([])
        setApplyClick(false)
        setLineChart(false)
        setScatterChart(false)
        //setVizbody(vizFilterbody1)
    }


    const onFilterTextBoxChanged = (e, type) => {
        const searchtext = e.target.value;
        // console.log(searchtext)
        // console.log(vizfilterdata)

        // setVizfilterdata(prevState => prevState.map(obj => 
        //     obj.value === type.value ? {...obj, filtertext:searchtext} : obj
        //   ));
        // Update vizfilterdata with new filtertext for matching type.value
        const updatedVizfilterdata = vizfilterdata.map(obj =>
            obj.value === type.value ? { ...obj, filtertext: searchtext } : obj
        );
        setVizfilterdata(updatedVizfilterdata);
        // console.log(updatedVizfilterdata);
        // console.log(vizfilterdata)

        var type_name = type.value

        let vizFilterbody1 = { ...vizFilterbody };
        updatedVizfilterdata.forEach((item) => {
            const { value, filtertext } = item;
            // console.log(value, filtertext)
            // console.log(type_name)
            // if(type_name === value)
            // {
            //     console.log('jjjjjjjjjj')
            //     vizFilterbody1[value] = vizFilterbody1[value]
            //     .filter(item => item.toLowerCase().includes(searchtext.toLowerCase()))
            //     .sort((a, b) => a.localeCompare(b));
            // }
            if (vizFilterbody1[value]) {
                console.log('iiiiiiiiiiiiiiiiiii')
                vizFilterbody1[value] = vizFilterbody1[value]
                    .filter(item => item.toLowerCase().includes(filtertext.toLowerCase()))
                    .sort((a, b) => a.localeCompare(b));
            }


        })
        //console.log(vizFilterbody1)
        setVizbody(vizFilterbody1)


        //var type_name  = type.value
        // let sortedObject = Object.entries(vizFilterbody).reduce((acc, [key, values]) => {
        //     //acc[key] = [...value].sort((a, b) => a.localeCompare(b));
        //    // acc[key] = value.map(v => v === ''?'Empty':v).sort((a, b) => a.localeCompare(b));
        //    if(type_name === key)
        //     {
        //         acc[key] = values.filter(item => {
        //             return item.toLowerCase().includes(searchtext.toLowerCase());
        //         });

        //     }
        //     else
        //     {
        //         acc[key] = values.map(v => v === ''?'Empty':v).sort((a, b) => a.localeCompare(b))
        //     }


        //     return acc;
        // }, {});
        //console.log(sortedObject)
        // setVizbody(sortedObject)
        //vizFilterbody
    }

    const toggleCollapse = (header, event) => {
        // console.log(event)
        // console.log(event.target.ariaExpanded)
        // console.log(header)
        // console.log(vizfilterdata)
        const updatedVizfilterdata = vizfilterdata.map(obj =>
            obj.value === header.value ? { ...obj, show: (obj.show === 'show' ? '' : 'show') } : obj
        );
        setVizfilterdata(updatedVizfilterdata);
        //console.log(updatedVizfilterdata);
        //   console.log(vizfilterdata) 
        // const objectTotoggle = event;
        // const foundobj = vizfilterdata.find(item => item.value === objectTotoggle.value);
        // console.log(foundobj)
        //const foundObject = array.find(item => item.id === obj.id && item.name === obj.name);
    }

    const toggleCheck = (event, header, item) => {
        // console.log(header)
        // console.log(item)
        // console.log(vizfilterdata)
        // console.log(event)
        // console.log(event.target.checked)
        // console.log(event.target.value)
        localStorage.setItem("lastselectedheader", JSON.stringify(header));
        // console.log(vizFilterbody)
        // console.log(vizFilterbody[header.value])
        let headerdata = vizFilterbody[header.value]
        localStorage.setItem("lastselectedheaderdata", JSON.stringify(headerdata));
        const updatedVizfilterdata = vizfilterdata.map(obj => {
            if (obj.value === header.value) {
                if (event.target.checked) {
                    return { ...obj, selectedItem: [...obj.selectedItem, item] }
                } else {
                   // console.log(obj.selectedItem)
                    obj.selectedItem = obj.selectedItem.filter(item1 => item1 !== item);
                    return obj
                    // return {...obj, selectedItem: [...obj.selectedItem, item]}
                }
                // return {...obj, selectedItem: [...obj.selectedItem, item]}
            }
            else {
                return obj
            }
        }
            // obj.value === header.value ? {...obj, selectedItem: [item]} : obj
        );
        setVizfilterdata(updatedVizfilterdata);
        filterbyCheck(updatedVizfilterdata)
        //console.log(updatedVizfilterdata);
        //console.log(vizfilterdata) 
    }


    const toggleCheckLabel = (event, header, item) => {
        // console.log(header)
        // console.log(item)
        //console.log(vizfilterdata)
        localStorage.setItem("lastselectedheader", JSON.stringify(header));
        // console.log(vizFilterbody)
        // console.log(vizFilterbody[header.value])
        let headerdata = vizFilterbody[header.value]
        localStorage.setItem("lastselectedheaderdata", JSON.stringify(headerdata));
        const updatedVizfilterdata = vizfilterdata.map(obj => {
            if (obj.value === header.value) {
                if (obj.selectedItem.includes(item)) {
                    obj.selectedItem = obj.selectedItem.filter(item1 => item1 !== item);
                    return obj

                } else {
                    return { ...obj, selectedItem: [...obj.selectedItem, item] }

                }

            }
            else {
                return obj
            }
        }
            // obj.value === header.value ? {...obj, selectedItem: [item]} : obj
        );
        setVizfilterdata(updatedVizfilterdata);
        filterbyCheck(updatedVizfilterdata)
        //console.log(updatedVizfilterdata);
        //console.log(vizfilterdata) 
    }

    const selectAll = (header, event) => {
        //console.log(header)

        //console.log(vizfilterdata)
        localStorage.setItem("lastselectedheader", JSON.stringify(header));
        // console.log(vizFilterbody)
        // console.log(vizFilterbody[header.value])
        let headerdata = vizFilterbody[header.value]
        localStorage.setItem("lastselectedheaderdata", JSON.stringify(headerdata));
        const updatedVizfilterdata = vizfilterdata.map(obj => {
            if (obj.value === header.value) {
                return { ...obj, selectedItem: headerdata }

            }
            else {
                return obj
            }
        }
            // obj.value === header.value ? {...obj, selectedItem: [item]} : obj
        );
        setVizfilterdata(updatedVizfilterdata);
        filterbyCheck(updatedVizfilterdata)
        //console.log(updatedVizfilterdata);
    }

    const resetAll = (header, event) => {
        console.log(header)

        //console.log(vizfilterdata)
        localStorage.setItem("lastselectedheader", JSON.stringify(header));
        // console.log(vizFilterbody)
        // console.log(vizFilterbody[header.value])
        let headerdata = vizFilterbody[header.value]
        localStorage.setItem("lastselectedheaderdata", JSON.stringify(headerdata));
        const updatedVizfilterdata = vizfilterdata.map(obj => {
            if (obj.value === header.value) {
                return { ...obj, selectedItem: [] }

            }
            else {
                return obj
            }
        }
            // obj.value === header.value ? {...obj, selectedItem: [item]} : obj
        );
        setVizfilterdata(updatedVizfilterdata);
        filterbyCheck(updatedVizfilterdata)
        //console.log(updatedVizfilterdata);
    }
    function filterbyCheck(updatedVizfilterdata) {
        var basicfiltertypeparse = JSON.parse(localStorage.getItem('basicfiltertype'))
        if (updatedVizfilterdata.length > 0) {
            localStorage.setItem("restoreadvancefilter", JSON.stringify(updatedVizfilterdata));
        }

        //console.log(basicfiltertypeparse)
        var finalfile_id = {}
        finalfile_id = { 'file_id': basicfiltertypeparse['file_id'] }
        //console.log(updatedVizfilterdata)
        var tem = JSON.parse(localStorage.getItem('selectedtemplate'))
        var vizObject = {};
        updatedVizfilterdata.forEach(status => {
            if (status.selectedItem.length > 0) {
                //console.log(status.selectedItem)
                vizObject[status.value] = status.selectedItem;

            }
            // else {
            //     vizObject = {}
            // }

        });
       // console.log(vizObject)
        const user = JSON.parse(localStorage.getItem("userDetails"));
        var payload = {

            "callFor": "cascade",
            "partnerName": user.partner_name,
            "tabType": "vizPage",
            "templateID": tem.value.toString(),
            "templateName": tem.label,
            //"basicFiltersType": basicfiltertypeparse,
            "basicFiltersType": finalfile_id,
            "vizFilters": vizObject
        }
        //console.log(payload)

        // setVizdata(data_filter)
        // console.log(data_filter)
        // console.log('data_filter')


        // if ('result' in data_filter && data_filter['status'] === 'success') {
        //     console.log(data_filter)
        //     var headers = data_filter?.['result']['filterList'];
        //     var body = data_filter?.['result']['filterValues'];
        //     var xaxis = data_filter?.['result']['xAxis'];
        //     var yaxis = data_filter?.['result']['yAxis'];
        //     var shape = data_filter?.['result']['shapeBy'];
        //     var color = data_filter?.['result']['colorBy'];
        //     // let selectedheader = JSON.parse(localStorage.getItem("lastselectedheader"));
        //     // let selectedheaderdata = JSON.parse(localStorage.getItem("lastselectedheaderdata"));
        //     // if(selectedheaderdata !== null)
        //     // {
        //     //     body[selectedheader.value] = selectedheaderdata
        //     // }
        //     let sortedObject = Object.entries(body).reduce((acc, [key, value]) => {
        //         //acc[key] = [...value].sort((a, b) => a.localeCompare(b));

        //         acc[key] = value.map(v => v === '' ? 'Empty' : v.toString()).sort((a, b) => a.localeCompare(b));
        //         return acc;
        //     }, {});
        //     console.log(sortedObject)
        //     console.log(vizheaders)
        //     setVizbody(sortedObject)
        //     setVizFilterbody(sortedObject)

        // }
        // if ('result' in data_filter && data_filter['status'] === 'failed') {

        //     setErrormessage(data_filter['result'])

        // }





        //generateRightSide(xaxis, yaxis, shape, color)
        setErrormessage('')
        setLoading(true)
        submitBasicFilter(payload).then((datas) => {
           // console.log(data_filter)
            setLoading(false)
            setVizdata(data_filter)
            // console.log(data_filter)
            // console.log('data_filter')
            
            var data_filter = datas.data;
            if ('result' in data_filter && data_filter['status'] === 'success' && Object.keys(data_filter?.['result']['filterValues']).length > 0) {
                //console.log(data_filter)
                var headers = data_filter?.['result']['filterList'];
                var body = data_filter?.['result']['filterValues'];
                var xaxis = data_filter?.['result']['xAxis'];
                var yaxis = data_filter?.['result']['yAxis'];
                var shape = data_filter?.['result']['shapeBy'];
                var color = data_filter?.['result']['colorBy'];
                let selectedheader = JSON.parse(localStorage.getItem("lastselectedheader"));
                let selectedheaderdata = JSON.parse(localStorage.getItem("lastselectedheaderdata"));
                if (selectedheaderdata !== null) {
                    body[selectedheader.value] = selectedheaderdata
                }
                let sortedObject = Object.entries(body).reduce((acc, [key, value]) => {
                    //acc[key] = [...value].sort((a, b) => a.localeCompare(b));
                    var uniqueArray = [...new Set(value)];
                    acc[key] = uniqueArray.map(v => (v === ' ' || v === null || v === 'None') ? 'Empty' : v.toString());

                    //acc[key] = uniqueArray.map(v => (v === ' ' || v === null || v === 'None') ? 'Empty' : v.toString()).sort((a, b) => a.localeCompare(b));
                    return acc;
                }, {});
                // console.log(sortedObject)
                // console.log(vizheaders)
                setVizbody(sortedObject)
                setVizFilterbody(sortedObject)

            }
            if ('result' in data_filter && data_filter['status'] === 'failed') {

                setErrormessage(data_filter['result'])

            }
            if ('result' in data_filter && data_filter['status'] === 'success' && Object.keys(data_filter?.['result']['filterValues']).length === 0) {
                alert("No data come from server")
                setErrormessage("No data come from server")
                return false
            }


        })
            .catch(error => {
                setLoading(false);
                //toast.error(error.toString())
                //setErrormessage(error.toString())
                //console.error('There was an error!', error);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.status === 401) {
                        console.log("initSSO: Token invalid - let's try again,token epired");
                        window.localStorage.removeItem("hashToken");
                        window.localStorage.removeItem("accessToken");
                        window.open(window.location.origin + "/api/link", "_self");
                    }

                    else if (error.response.status === 403) {
                        toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')

                    }
                    else {

                        setErrormessage(error.toString());

                    }
                } else {
                    // Something happened in setting up the request that triggered an Error
                    toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')

                }
                setLoading(false)

            });
    }

    const setSelectedOptionRight = (e, type) => {
        //console.log(e)
        console.log('klkkkkkkkkkkkkkkkk')
        //console.log(Graphdata)
        if (type === 'xaxis') {

            setxaxisdefault(e)
            localStorage.setItem("xaxis", JSON.stringify(e))

        }
        if (type === 'yaxis') {
            setyaxisdefault(e)
            localStorage.setItem("yaxis", JSON.stringify(e))
        }
        if (type === 'shapeby') {
            setshapedefault(e)
            localStorage.setItem("shape", JSON.stringify(e))
        }
        if (type === 'colorby') {
            setcolordefault(e)

            localStorage.setItem("color", JSON.stringify(e))
        }
        if (scatterBlock === true) {
            setScatterChart(true)
            setLineChart(false)
            generateSctteredChart(Graphdata)
        }
        else {
            setScatterChart(false)
            setLineChart(true)
            //generateLineChartOnchangeXorYaxis(e, type)
            generateLineChart(Graphdata)
        }

    }

    const generateLineChartOnchangeXorYaxis = (e, type) => {
        console.log('line')
        // console.log(Graphdata)
        // console.log(e, type)
        let filteredData = Graphdata;
        vizfilterdata.forEach(searchItem => {
           // console.log(searchItem['selectedItem'])
            if (searchItem['selectedItem'].length > 0) {
                filteredData = filteredData.filter(dataItem =>

                    searchItem['selectedItem'].includes(dataItem[searchItem.value].toString())
                );
            }
        });
       // console.log(filteredData)
        var xlable = (type === 'xaxis') ? e.label : xaxisdefault.label
        var xvalue = (type === 'xaxis') ? e.value : xaxisdefault.value
        var yvalue = (type === 'yaxis') ? e.value : yaxisdefault.value
        var ylable = (type === 'yaxis') ? e.label : yaxisdefault.label
        //console.log(xlable, xvalue, yvalue, ylable)
    }

    const generateSctteredChart = (body) => {
        console.log('scatter')
        setErrormessage('')
        //console.log(graphjson)
        // console.log(Graphdata)
        // console.log(body)
        // console.log(xaxisdefault)
        // console.log(yaxisdefault.value)
        // console.log(vizfilterdata)
        // console.log(GraphdataType)
        localStorage.setItem("lastselectedchart", 'scatter');
        let xaxis = JSON.parse(localStorage.getItem("xaxis"))
        let yaxis = JSON.parse(localStorage.getItem("yaxis"))
        let shape = JSON.parse(localStorage.getItem("shape"))
        let color = JSON.parse(localStorage.getItem("color"))
        var colorlabel = color.value
        var shapelabel = shape.value
       // console.log(colorlabel)
        if (ApplyClick === true) {
            setScatterChart(true)
            setLineChart(false)
        }

        setscatterBlock(true)
        setlineBlock(false)
        let filteredData = body;
        let restoreadvancefilter = JSON.parse(localStorage.getItem("restoreadvancefilter"));
        //console.log(restoreadvancefilter)
        // vizfilterdata.forEach(searchItem => {
        //     console.log(searchItem['selectedItem'])
        //     if (searchItem['selectedItem'].length > 0) {
        //         filteredData = filteredData.filter(dataItem =>

        //             searchItem['selectedItem'].includes(dataItem[searchItem.value].toString())
        //         );
        //     }
        // });
        if (restoreadvancefilter !== null) {
            restoreadvancefilter.forEach(searchItem => {
               // console.log(searchItem['selectedItem'])
                if (searchItem['selectedItem'].length > 0) {
                    filteredData = filteredData.filter(dataItem =>

                        searchItem['selectedItem'].includes(dataItem[searchItem.value].toString())
                    );
                }
            });

        }
        else {
            vizfilterdata.forEach(searchItem => {
               // console.log(searchItem['selectedItem'])
                if (searchItem['selectedItem'].length > 0) {
                    filteredData = filteredData.filter(dataItem =>

                        searchItem['selectedItem'].includes(dataItem[searchItem.value].toString())
                    );
                }
            });
        }

        //console.log(filteredData);
        setFilterChartData(filteredData)
        setFilterGraphDataForFuture(filteredData)
        const uniqueData = filteredData.reduce((acc, current) => {
            const uniqueKey = `${current[xaxis.value]}-${current[yaxis.value]}`;
            if (!acc.seen[uniqueKey]) {
                acc.result.push(current);
                acc.seen[uniqueKey] = true;
            }
            return acc;
        }, { result: [], seen: {} }).result;

        //console.log(uniqueData);
        const colorlist = [...new Set(uniqueData.map(item => item[color.value]))];
        const shapelist = [...new Set(uniqueData.map(item => item[shape.value]))];
        //console.log(COLORS)
        var colorwisecolrcode = []
        colorlist.map((item, index) => {
            //console.log(item)
            var item1 = item === 'None' ? 'Empty' : item
            colorwisecolrcode.push({ [item1]: COLORS[index % COLORS.length] })
            //colorwisecolrcode.push({item:COLORS[index % COLORS.length]})
        });
        //console.log(colorwisecolrcode);
        var shapewiseshape = []
        shapelist.map((item, index) => {
           // console.log(item)
            shapewiseshape.push({ [item]: SHAPE[index % SHAPE.length] })
            //colorwisecolrcode.push({item:COLORS[index % COLORS.length]})
        });
        //console.log(shapewiseshape);
        setshapewiseshape(shapewiseshape)
        setcolorwisecolrcode(colorwisecolrcode)
        //COLORS[index % COLORS.length]
        // console.log(colorlist);
        // console.log(shapelist);
        // var xstring  = 0
        var scatterData = uniqueData.map((item) => {
            if (item[xaxis.value] === 'None' && item[yaxis.value] === 'None') {
                //console.log(item[xaxis.value], item[yaxis.value])
                setErrormessage('Your filter selction result contains only empty value , please change filter selection criteria')
            }
            // else if(item[yaxis.value] === 'None')
            // {
            //     console.log(item[xaxis.value], item[yaxis.value])
            //     setErrormessage('Your filter selction result contains only empty value , please change filter selection criteria')

            // }
            else {
                // console.log(item[xaxis.value], item[yaxis.value])
                setErrormessage('')
                //xstring = xstring + 50
                if (item[xaxis.value] !== 'None' || item[yaxis.value] !== 'None') {
                    return [{
                        name: item[xaxis.value],
                        [yaxis.value]: parseInt(item[yaxis.value]),
                        //[xaxis.value]: item[xaxis.value],
                        //[xaxis.value]: parseInt(item[xaxis.value]),
                        //[xaxis.value]: parseInt(item[xaxis.value]) ? parseInt(item[xaxis.value]) : xstring,

                        [xaxis.value]: parseInt(item[xaxis.value]) ? parseInt(item[xaxis.value]) : parseInt(item[yaxis.value]),
                        'xaxisname': xaxis.label,
                        'xaxisvalue': xaxis.value,
                        // [color.value]: item[color.value],
                        // [shape.value]: item[shape.value],
                        'color': getColorCode((item[color.value] === 'None') ? 'Empty' : item[color.value], colorwisecolrcode),
                        'shape': getShapeCode(item[shape.value], shapewiseshape),
                        'selectedcolor': (item[color.value] === 'None') ? 'Empty' : item[color.value],
                        'selectedshape': item[shape.value],
                    }]

                }


            }

        });
        //console.log(scatterData)

        // const combinedData = scatterData.reduce((acc, currentArray) => {
        //     const obj = currentArray[0];
        //     if (acc[obj.name]) {
        //         acc[obj.name].push(obj);
        //     } else {
        //         acc[obj.name] = [obj];
        //     }
        //     return acc;
        // }, {});

        // scatterData = Object.values(combinedData);

        // console.log(scatterData);



        if (scatterData.length >= 200) {
            setErrormessage('You have selected ' + scatterData.length + ' data points, Visualization works best with 200 data ponts, please use filter accordingly')
            localStorage.removeItem("lastselectedchart");

        }
        else {
            //console.log(scatterData);
            setScatterData(scatterData)
        }

        // localStorage.setItem("restoreadvancefilter", JSON.stringify(vizfilterdata));
        // console.log(vizfilterdata)
        // console.log(vizbody)
        // let filterConditions = [];
        // let restoreadvancefilter = JSON.parse(localStorage.getItem("restoreadvancefilter"));
        // restoreadvancefilter.forEach((item) => {
        //     if (item.selectedItem.length > 0) {
        //         filterConditions.push({ [item.value]: item.selectedItem });
        //     }
        // });
        // console.log(filterConditions)
        // const filteredVizbody = {};

        // vizfilterdata.forEach(filter => {
        //     if (vizbody[filter.value]) {
        //         // Filter the array in vizbody based on the selectedItem of the current filter
        //         filteredVizbody[filter.value] = vizbody[filter.value].filter(item =>
        //             filter.selectedItem.includes(item)
        //         );
        //     }
        // });

        // console.log(filteredVizbody);
    }
    const generateLineChart = (body) => {
        console.log('line')
        setErrormessage('')
        // console.log(Graphdata)
        // console.log(xaxisdefault)
        // console.log(yaxisdefault.value)
        // console.log(vizfilterdata)
        // console.log(GraphdataType)
        localStorage.setItem("lastselectedchart", 'line');
        // [
        //     {
        //         name: 'Page A',
        //         y: 4000,
        //         x: 2400,
        //         amt: 2400,
        //       }
        // ]
        let filteredData = body;
        let restoreadvancefilter = JSON.parse(localStorage.getItem("restoreadvancefilter"));
        //console.log(restoreadvancefilter)
        let xaxis = JSON.parse(localStorage.getItem("xaxis"))
        let yaxis = JSON.parse(localStorage.getItem("yaxis"))
        if (restoreadvancefilter !== null) {
            restoreadvancefilter.forEach(searchItem => {
                //console.log(searchItem['selectedItem'])
                if (searchItem['selectedItem'].length > 0) {
                    filteredData = filteredData.filter(dataItem =>

                        searchItem['selectedItem'].includes(dataItem[searchItem.value].toString())
                    );
                }
            });

        }
        else {
            vizfilterdata.forEach(searchItem => {
                //console.log(searchItem['selectedItem'])
                if (searchItem['selectedItem'].length > 0) {
                    filteredData = filteredData.filter(dataItem =>

                        searchItem['selectedItem'].includes(dataItem[searchItem.value].toString())
                    );
                }
            });
        }

       // console.log(filteredData);
        setFilterChartData(filteredData)
        setFilterGraphDataForFuture(filteredData)
        // if (GraphdataType === 'filterValues') {
        //     if (filteredData.length > 0) {
        //         var lineData = filteredData.map((item) => {
        //             return {
        //                 name: item[xaxisdefault.value],
        //                 [yaxisdefault.value]: item[yaxisdefault.value],
        //                 [xaxisdefault.value]: item[xaxisdefault.value],
        //                 'xaxisname': xaxisdefault.label,
        //                 'xaxisvalue': xaxisdefault.value
        //             }
        //         });
        //         console.log(lineData)
        //         setLineData(lineData)
        //     }
        //     else {
        //         var lineData = Graphdata.map((item) => {
        //             return {
        //                 name: item[xaxisdefault.value],
        //                 [yaxisdefault.value]: item[yaxisdefault.value],
        //                 [xaxisdefault.value]: item[xaxisdefault.value],
        //                 //amt: item[yaxisdefault.value],
        //                 'xaxisname': xaxisdefault.label,
        //                 'xaxisvalue': xaxisdefault.value
        //             }
        //         });
        //         console.log(lineData)
        //         setLineData(lineData)

        //     }

        // }
        // else {
        const uniquexaxixvalue = [...new Set(filteredData.map(item => item[xaxis.value]))];
       // console.log(uniquexaxixvalue)

        //const uniquexaxisvalue = ['2', '3'];
        const sumOfy = {};
        let maxLength = 0;
        uniquexaxixvalue.forEach(value => {
            sumOfy[value] = 0; // Initialize sum for each unique x-axis value
            if (value.length > maxLength) {
                maxLength = value.length;
                if (maxLength < 10) {
                    setMaxLength(50)
                }
                else {
                    setMaxLength(100)
                }

            }
            filteredData.forEach(item => {
                if (item[xaxis.value] === value) {
                    //console.log(item[yaxis.value])
                    //sumOfy[value] += Number(item[yaxis.value]); // Sum lot numbers
                    if (item[yaxis.value] !== 'None') {
                        sumOfy[value] += item[yaxis.value];
                    }



                }
            });
        });

        // console.log(sumOfy);
        // console.log(maxLength * 10)
        const lineData = Object.entries(sumOfy)
            .filter(([name, result_numeric]) => result_numeric !== 0) // Filter out entries with value 0
            .map(([name, result_numeric]) => ({
                // name,
                name: (name === 'None') ? '(Empty)' : name,
                // [yaxis.value]: Number(result_numeric) / ArrLength(name, filteredData),
                [yaxis.value]: result_numeric / ArrLength(name, filteredData),
                [xaxis.value]: (name === 'None') ? '(Empty)' : name,
                // [xaxis.value]: name,
                amt: result_numeric,
                xaxisname: xaxis.label,
                xaxisvalue: xaxis.value
            }));
        if (lineData.length === 0) {
            setErrormessage('Your filter selction result contains only empty value , please change filter selection criteria')
        }
        //console.log(lineData)
        setLineData(lineData)

        //  }




        if (ApplyClick === true) {
            setScatterChart(false)
            setLineChart(true)
            // setLineData([])
        }

        setscatterBlock(false)
        setlineBlock(true)



    }

    const getColorCode = (color, colorwisecolrcode) => {
        // console.log(color)
        let valueOfKey10 = null;
        for (const obj of colorwisecolrcode) {
            if (obj.hasOwnProperty(color)) {
                valueOfKey10 = obj[color];
                break;
            }
        }
        return valueOfKey10;


    }

    const getShapeCode = (shape, shapewiseshape) => {
        // console.log(shape)
        let valueOfKey10 = null;
        for (const obj of shapewiseshape) {
            if (obj.hasOwnProperty(shape)) {
                valueOfKey10 = obj[shape];
                break;
            }
        }
        return valueOfKey10;
    }

    const ArrLength = (name, filteredData) => {
        var count = 0;
        let xaxis = JSON.parse(localStorage.getItem("xaxis"))
        filteredData.forEach(item => {
            if (item[xaxis.value] === name) {
                count += 1; // Sum lot numbers

            }
        });
        // console.log(count)
        return count
    }



    const vizApply = () => {
        setApplyClick(true)
        //console.log(scatterBlock)
        localStorage.setItem("restoreadvancefilter", JSON.stringify(vizfilterdata));
        //localStorage.setItem("lastselectedchart", 'scatter');
        chartDataPopulation()

    }


    function chartDataPopulation() {
        var basicfiltertypeparse = JSON.parse(localStorage.getItem('basicfiltertype'))
        // console.log(basicfiltertypeparse)
        // console.log(vizfilterdata)
        let restoreadvancefilter = JSON.parse(localStorage.getItem("restoreadvancefilter"));
        var lastselectedchart = localStorage.getItem("lastselectedchart")
        // console.log(lastselectedchart)
        // console.log(restoreadvancefilter)
        var tem = JSON.parse(localStorage.getItem('selectedtemplate'))
        var vizObject = {};
        vizObject['file_id'] = basicfiltertypeparse['file_id']
        // vizfilterdata.forEach(status => {
        //     if (status.selectedItem.length > 0) {
        //         vizObject[status.value] = status.selectedItem

        //     }


        // });
        restoreadvancefilter.forEach(status => {
            if (status.selectedItem.length > 0) {
                vizObject[status.value] = status.selectedItem

            }


        });
        //console.log(vizObject)
        localStorage.setItem("basicfiltertype", JSON.stringify(vizObject));
        const user = JSON.parse(localStorage.getItem("userDetails"));
        var payload = {

            //"callFor": "cascade",
            "partnerName": user.partner_name,
            //"tabType": "vizPage",
            "templateID": tem.value.toString(),
            "templateName": tem.label,
            "tabType": "viz",
            //"basicFiltersType": basicfiltertypeparse,
            "vizFilters": vizObject
        }
        //console.log(payload)
        setLoading(true)
        setErrormessage('')
        submitAdvanceFilter(payload).then((datas) => {
            ////console.log(graphjson)
            setLoading(false)
            console.log('graphjson')
            var graphjson = datas.data;
            if ('result' in graphjson && graphjson['status'] === 'success') {
                //console.log(graphjson)
                var headers = graphjson?.['result']['headers'];
                if ('data' in graphjson?.['result']) {
                    var body = graphjson?.['result']['data'];
                    setGraphdata(body)
                    var filterheder = graphjson?.['result']['headers']
                    setinitialheaderstable(filterheder)
                    setGraphdataType('data')

                }
                if ('filterValues' in graphjson?.['result']) {
                    var body = graphjson?.['result']['filterValues'];
                    setGraphdata(body)
                    var filterheder = graphjson?.['result']['headers']
                    setinitialheaderstable(filterheder)
                    setGraphdataType('filterValues')
                }

                // console.log(headers)
                // console.log(body)
                // if (scatterBlock === true) {
                if (lastselectedchart === 'scatter') {
                    localStorage.setItem("lastselectedchart", 'scatter');

                    setScatterChart(true)
                    setLineChart(false)
                    generateSctteredChart(body)
                }
                else if (lastselectedchart === 'line') {
                    localStorage.setItem("lastselectedchart", 'line');
                    setScatterChart(false)
                    setLineChart(true)
                    generateLineChart(body)
                }
                else {
                    localStorage.setItem("lastselectedchart", 'scatter');

                    setScatterChart(true)
                    setLineChart(false)
                    generateSctteredChart(body)
                }

            }
            if ('result' in graphjson && graphjson['status'] === 'failed') {

                setErrormessage(graphjson['result'])

            }
        })
            .catch(error => {
                setLoading(false);
                //toast.error(error.toString())
                //setErrormessage(error.toString())
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.status === 401) {
                        console.log("initSSO: Token invalid - let's try again,token epired");
                        window.localStorage.removeItem("hashToken");
                        window.localStorage.removeItem("accessToken");
                        window.open(window.location.origin + "/api/link", "_self");
                    }

                    else if (error.response.status === 403) {
                        toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')

                    }
                    else {

                        setErrormessage(error.toString());

                    }
                } else {
                    // Something happened in setting up the request that triggered an Error
                    toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')

                }
                //console.error('There was an error!', error);
                setLoading(false)

            });




    }
    const isChecked = (header, item) => {
        // console.log(header)
        //console.log(item)
        if (header.selectedItem.includes(item)) {
            return true

        }
        else {
            return false
        }
    }



    const CustomTooltip = (props) => {
        const { active, payload } = props;

        if (active && payload && payload.length) {
            //console.log(props)
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: "white",
                        border: "1px solid black",
                        pointerEvents: "auto",
                        padding: "5px",
                        fontFamily: 'Quicksand',
                    }}
                >
                    <div style={{ fontSize: 14, fontWeight: '600', ml: 2 }}>

                        {payload.reverse().map((item, index) => {
                            return (
                                <>
                                    <div key={index} className="d-flex align-items-center px-1">
                                        <div style={{ width: '8px', height: '8px', backgroundColor: item.fill }} className="mr-1"></div>
                                        <div>
                                            <p className="mb-0 text-muted" style={{ fontSize: '12px' }}>
                                                {/* {`${item.name}: ${item.payload[item.dataKey]}`} */}
                                                {/* {`${item.name}: ${parseFloat(item.payload[item.dataKey]).toFixed(3)}`} */}
                                                {`${item.name}: ${Number.isInteger(item.payload[item.dataKey]) ? item.payload[item.dataKey] : parseFloat(item.payload[item.dataKey]).toFixed(3)}`}


                                            </p>
                                            <p className="mb-0 text-muted" style={{ fontSize: '12px' }}>

                                                {`${item.payload['xaxisname']}: ${item.payload[item.payload['xaxisvalue']]}`}
                                                {/* {`${item.payload['xaxisname']}: ${parseFloat(item.payload[item.payload['xaxisvalue']]).toFixed(3)}`} */}
                                                {/* {`${item.payload['xaxisname']}: ${Number.isInteger(item.payload[item.payload['xaxisvalue']]) ? item.payload[item.payload['xaxisvalue']] : parseFloat(item.payload[item.payload['xaxisvalue']]).toFixed(3)}`} */}

                                            </p>
                                        </div>
                                    </div>

                                </>
                            )
                        })}
                    </div>

                </div>
            );
        }
    };

    const CustomTooltipScatter = (props) => {
        const { active, payload } = props;
        let color = JSON.parse(localStorage.getItem("color"))
        if (active && payload && payload.length) {
            // console.log(props)
            // console.log(gcolor)
            // const objectAtTargetIndex = colorwisecolrcode[gcolor]; // Get the object at index 1
            // const key = Object.keys(objectAtTargetIndex)[0]; // Get the key of the object
            //const value = objectAtTargetIndex[key]; // Get the value using the key
            const value = payload[0].payload['color'];
            const objectAtTargetIndex = colorwisecolrcode[payload[0].payload['color']]; // Get the object at index 1
            //const key = Object.keys(objectAtTargetIndex)[0];
            //console.log(objectAtTargetIndex)
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: "white",
                        border: "1px solid black",
                        pointerEvents: "auto",
                        padding: "5px",
                        // fontFamily: 'Quicksand',
                    }}
                >
                    <div style={{ fontSize: 14, fontWeight: '600', ml: 2 }}>

                        {/* {payload.reverse().map((item, index) => { */}

                        <>

                            <div className="d-flex align-items-center px-1">
                                <div style={{ width: '8px', height: '8px', backgroundColor: payload[0].fill }} className="mr-1"></div>
                                <div>
                                    {/* <p className="mb-0 text-muted" style={{ fontSize: '12px' }}>
                                        {`${payload[0].name}: ${payload[0].payload[payload[0].dataKey]}`}
                                    </p> */}
                                    <p className="mb-0 text-muted" style={{ fontSize: '12px' }}>
                                        {`${payload[0].name}: ${payload[0].payload['name']}`}
                                    </p>
                                    <p className="mb-0 text-muted" style={{ fontSize: '12px' }}>
                                        {`${payload[1].name}: ${payload[1].payload[payload[1].dataKey]}`}
                                    </p>
                                    {/* <p>
                                            <div style={{ width: '8px', height: '8px', backgroundColor: gcolor}} className="mr-1"></div> {`${payload[0].payload['xaxisname']}`}: {`${payload[0].payload[payload[0].payload['xaxisvalue']]}`} 
                                               
                                            </p> */}
                                    <p className="mb-0 text-muted d-flex align-items-center " style={{ fontSize: '12px' }}>
                                        <div style={{ width: '8px', height: '8px', backgroundColor: value }} className="mr-1"></div> {`${color.label}`}: {`${payload[0].payload['selectedcolor']}`}  {/* {`${payload[0].payload['xaxisname']}: ${payload[0].payload[payload[0].payload['xaxisvalue']]}`} */}

                                    </p>


                                </div>
                            </div>

                        </>


                        {/* })} */}
                    </div>

                </div>
            );
        }
    }

    const CustomizedAxisTick = ({ x, y, payload }) => {
        // console.log(props)
        // console.log(x, y, payload)
        var strarr = payload?.value.split(' ')
        //console.log(strarr)
        // if(payload?.value.indexOf(' ') >= 0)
        // {
        return (
            // <g transform={`translate(${x},${y})`}>
            //     <text x={0} y={0} dy={16} fontSize={8} fill="#666" fontWeight={600} transform="rotate(-35)">
            //         {
            //             payload?.value.split(" ").map((value, i) => {
            //                 return (

            //                     value && <tspan textAnchor="end" fill="#666" transform="rotate(-35)" x="0" dy={10} key={i}>{value}</tspan>
            //                 );
            //             }

            //             )
            //         }

            //     </text>
            // </g>
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                    {payload.value}
                </text>
            </g>
        );
        //}
        // else
        // {
        //     return (
        //         <g transform={`translate(${x},${y})`}>
        //             <text x={0} y={0} dy={8} fontSize={8} fill="#666" fontWeight={600}>
        //                 {
        //                     <tspan textAnchor="middle" x="0" dy={10}>{payload?.value}</tspan>

        //             }

        //             </text>
        //         </g>
        //     );
        // }


    }
    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const handlePointClick = (event, data) => {
        // console.log('Point clicked:', data);
        // console.log(event)
        // console.log(filterGraphDataFuture)
        let xaxis = JSON.parse(localStorage.getItem("xaxis"))
        let yaxis = JSON.parse(localStorage.getItem("yaxis"))
        //console.log(xaxis.value, yaxis.value)
        if (isEmptyObject(event)) {
            console.log("Event object is empty");
            return;
        }

        //get xaxis value
        let xaxisvalue = event['activePayload'][0].payload[xaxis.value]
        //console.log(xaxisvalue)
        var serachscatter = { [xaxis.value]: xaxisvalue }
        //console.log(serachscatter)

        const filteredData = filterGraphDataFuture.filter(item =>
            Object.keys(serachscatter).every(key => item[key] === serachscatter[key])
        );
        setFilterChartData(filteredData)
        setClickedPoint({ x: xaxisvalue });

        // Add your custom logic here
    };
    function handleMouseDown(e) {
        let xaxisvalue = e['activePayload'][0].payload[XAxis.value]
      
        setClickedPoint({ x: xaxisvalue });
        
      }
    const resetChart = () => {
        setFilterChartData(filterGraphDataFuture);
        setClickedPoint(false);
    }

    const handlePointClickScatter = (event, data) => {
        //console.log(event, data)
        //console.log(filterGraphDataFuture)
        setClickedPoint(false)
        let xaxis = JSON.parse(localStorage.getItem("xaxis"))
        let yaxis = JSON.parse(localStorage.getItem("yaxis"))
        //console.log(xaxis.value, yaxis.value)
        var serachscatter = { [xaxis.value]: data[0]['name'], [yaxis.value]: data[0][yaxis.value] }
        //console.log(serachscatter)

        const filteredData = filterGraphDataFuture.filter(item =>
            Object.keys(serachscatter).every(key => item[key] === serachscatter[key])
        );


        //console.log(filteredData);
        const uniqueData = filteredData.reduce((acc, current) => {
            const uniqueKey = `${current[xaxis.value]}-${current[yaxis.value]}`;
            if (!acc.seen[uniqueKey]) {
                acc.result.push(current);
                acc.seen[uniqueKey] = true;
            }
            return acc;
        }, { result: [], seen: {} }).result;

        //console.log(uniqueData);
        setFilterChartData(uniqueData)
        //setClickedPoint({ x: data[0]['name'], y: data[0][yaxis.value] });
        setClickedPoint(true)
    }

    const handleGraphClick = () => {
        // Reset the table data to its initial state
        setFilterChartData(filterGraphDataFuture);
        setClickedPoint(false);
    };


    return (

        <>
            <div className="row">

                <div className="col-6">
                    <div className="form-group">

                        {/* {errormessage && (
                            <span
                                className={` ${Accounttemplatestyle.mandetoryfield}`}
                            >
                                {errormessage}
                            </span>
                        )} */}
                        {errormessage && (
                            <div className=" col-12 mt-2 ml-4 alert alert-danger  " role="alert">
                                <i className="fa fa-exclamation-circle mr-2" style={{ color: 'red', fontSize: '19px' }}></i>{errormessage}
                            </div>
                        )}

                        {/* <span className="text-success mb-3 globaltextformat ">{successmessage}</span> */}
                    </div>
                </div>
                <div className="col-6 text-right">
                    <span className="text-warning pull-right report-warning-info">
                        <i className="ace-icon fa fa-exclamation-triangle"></i>
                        This visualization is for information only &amp; cannot be used for any GXP purposes
                    </span>
                </div>
            </div>
            <div style={{ height: '570px' }} className="tab-content_vix" id="style-3">
                <div className="vizparent" style={{ height: '100%' }}>
                    <div className="viz-filter-main px-0" id="style-3" >
                        <div class="col-xs-12 mt-3 "  >
                            <h4 className="globaltextformat font500 px-2 pb-2" style={{ fontSize: '16px' }}>Advanced Filters
                                <span style={{ float: 'right', cursor: 'pointer' }}><i onClick={clickPencil} className="fa fa-pencil"></i></span>
                            </h4>
                            <div className="text-center pb-2">



                                <button type="button" onClick={vizApply} className={`btn btn-primary text-wrap fw-normal submit_btnapp`}>
                                    <i className="fa fa-check"></i> Apply
                                </button>
                                <button type="button" onClick={resetFilter} className="btn   text-wrap fw-normal submit-reset" id="submit-reset" style={{ margin: '0 10px', backgroundColor: '#ba6868', color: 'white' }}>
                                    <i className="fa fa-refresh"></i>   Reset
                                </button>


                            </div>
                            <div className="px-2 pb-2 tab-content_vix_filter">

                                {
                                    Clicked ?
                                        <Select
                                            defaultValue={selectedOptionval}
                                            placeholder="Add Filter"
                                            className={` select-template  `}
                                            onChange={setSelectedOption}
                                            options={vizheaders}
                                            isSearchable={true}
                                            classNamePrefix="selectDropdown"
                                            aria-label="Default select example"
                                            hideSelectedOptions={true}
                                            value=""
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderRadius: "0em 0.25em   0.25em 0em",

                                                }),

                                                option: (
                                                    styles,
                                                    { data, isDisabled, isFocused, isSelected }
                                                ) => ({
                                                    ...styles,
                                                    cursor: "pointer",
                                                    display: "block",
                                                    fontSize: "0.9rem",
                                                    width: "100%",
                                                    padding: "5px 12px",
                                                    boxSizing: "border-box",
                                                    borderBottom: data?.className ? "1px solid #8e8e8e" : "",
                                                }),
                                            }}
                                        />
                                        : null
                                }
                            </div>
                            <div className="mt-0 pb-2 " id="style-3">
                                {/* {openAccordian} */}

                                {

                                    vizfilterdata.map((header, index) => (
                                        <div className="accordian">
                                            <div className=" advancefilterst">


                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className=" p-0" data-toggle="collapse" onClick={(event) => toggleCollapse(header, event)} role="button" aria-expanded="false" aria-controls={header.value}>
                                                            {header.show === 'show' ? <i className="fa fa-chevron-down"></i> : <i className="fa fa-chevron-right"></i>}
                                                            <span style={{ wordBreak: 'break-word' }}>{header.label}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <button style={{ float: 'right' }} className="btn ml-1 btn-white btn-mini btn-primary select-all-filter" title="Select All" onClick={(event) => selectAll(header, event)}><i className="fa fa-check"></i></button>

                                                        <button className="ml-1 btn btn-white btn-mini btn-primary select-all-filter" title="Reset " onClick={(event) => resetAll(header, event)}><i className="fa fa-refresh"></i> </button>
                                                        <button className="btn ml-1 btn-white btn-mini btn-primary select-all-filter" title="Select All">{header.selectedItem.length}</button>

                                                        {header.delete ? <button className="btn btn-white btn-mini btn-primary select-all-filter" title="Remove" onClick={() => removeFilter(header)}><i className="fa fa-times"></i></button> : null}


                                                        {/* {header.delete ? <div onClick={() => removeFilter(header)}><span style={{ float: 'right', cursor: 'pointer' }}><i className="fa fa-times"></i></span></div> : null} */}
                                                    </div>
                                                </div>
                                                {/* <div className="btn p-0" data-toggle="collapse" onClick={(event) => toggleCollapse(header, event)} role="button" aria-expanded="false" aria-controls={header.value}>
                                                    {header.show === 'show' ? <i className="fa fa-chevron-down"></i> : <i className="fa fa-chevron-right"></i>}
                                                    {header.label}
                                                </div>
                                                <button style={{ float: 'right' }} className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All" onClick={(event) => selectAll(header, event)}><i className="fa fa-check"></i></button>

                                                <button className="ml-1 btn btn-white btn-mini btn-primary select-all-filter" title="Reset " onClick={(event) => resetAll(header, event)}><i className="fa fa-refresh"></i> </button>
                                                <button className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All">{header.selectedItem.length}</button>
                                               

                                                {header.delete ? <div onClick={() => removeFilter(header)}><span style={{ float: 'right', cursor: 'pointer' }}><i className="fa fa-times"></i></span></div> : null} */}

                                            </div >

                                            <div className={`${header.show}  collapse multi-collapse`} id={header.value}>

                                                <div className="card" >

                                                    <div className="card-body py-2">

                                                        <div className="col-12 pb-1 px-0 text-center" style={{ minHeight: 30 }}>


                                                            <div className="input-group searchBoxvisualisation" >
                                                                <div className="input-group-prepend border-right-0">
                                                                    <div
                                                                        className="input-group-text bg-transparent "
                                                                        id="btnGroupAddon2"
                                                                    >
                                                                        <i className="fa fa-search font-size-14"></i>
                                                                    </div>
                                                                </div>
                                                                <input
                                                                    className="form-control font-size-14 "
                                                                    id={`filter-text-box_${header.value}`}
                                                                    type="search"
                                                                    placeholder="Filter..."
                                                                    value={header.filtertext}
                                                                    onInput={(event) => onFilterTextBoxChanged(event, header)}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="col-12 pb-1 pt-1 px-0 bodyvisul" id="style-3">

                                                            {vizbody[header.value].map((item, index) => (
                                                                <div className={`form-check pb-1 checkbox-${item}`} key={index} >
                                                                    <input class="form-check-input" type="checkbox" onChange={(event) => toggleCheck(event, header, item)} checked={isChecked(header, item)} name={`checkbox-${item}[]`} value={item} id={`checkbox-${item}`}

                                                                    />
                                                                    <label class="form-check-label" checked={isChecked(header, item)} name={`checkbox-${item}[]`} value={item}
                                                                        id={`checkbox-${item}`} for="defaultCheck1" onClick={(event) => toggleCheckLabel(event, header, item)}>
                                                                        {/* {item} */}
                                                                        {(item === '' ? 'Empty' : item)}
                                                                    </label>
                                                                </div>
                                                            ))}

                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))

                                }



                            </div>



                        </div>

                    </div>
                    <div className="viz-chart-main scroller " id="style-3" >
                        <div className="row mx-0">


                            <div className="col-12 viz-option pb-3">
                                <div className="row">
                                    <div className="col-10" >
                                        {scatterBlock ?
                                            <div className="row">
                                                <div className="col-3 mt-2">
                                                    <label className="customLabelMargin font500">
                                                        Select Xaxis
                                                    </label>
                                                    <Select
                                                        value={xaxisdefault}
                                                        //placeholder="Select Xaxis"
                                                        className={` select-template  `}
                                                        onChange={(event) => { setSelectedOptionRight(event, 'xaxis') }}
                                                        options={xaxislist}
                                                        isSearchable={true}
                                                        classNamePrefix="selectDropdown"
                                                        aria-label="Default select example"
                                                        name="xaxis"
                                                        //hideSelectedOptions={true}
                                                        //value={selectedOptionxaxis}
                                                        styles={{
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                borderRadius: "0em 0.25em   0.25em 0em",

                                                            }),

                                                            option: (
                                                                styles,
                                                                { data, isDisabled, isFocused, isSelected }
                                                            ) => ({
                                                                ...styles,
                                                                cursor: "pointer",
                                                                display: "block",
                                                                fontSize: "0.9rem",
                                                                width: "100%",
                                                                padding: "5px 12px",
                                                                boxSizing: "border-box",
                                                                borderBottom: data?.className ? "1px solid #8e8e8e" : "",
                                                            }),
                                                        }}
                                                    />


                                                </div>
                                                <div className="col-3 mt-2">
                                                    <label className="customLabelMargin font500">
                                                        Select Yaxis
                                                    </label>
                                                    <Select
                                                        value={yaxisdefault}
                                                        //placeholder="Select Xaxis"
                                                        className={` select-template  `}
                                                        onChange={(event) => { setSelectedOptionRight(event, 'yaxis') }}
                                                        options={yaxislist}
                                                        isSearchable={true}
                                                        classNamePrefix="selectDropdown"
                                                        aria-label="Default select example"
                                                        name="yasis"
                                                        //hideSelectedOptions={true}
                                                        //value={selectedOptionxaxis}
                                                        styles={{
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                borderRadius: "0em 0.25em   0.25em 0em",

                                                            }),

                                                            option: (
                                                                styles,
                                                                { data, isDisabled, isFocused, isSelected }
                                                            ) => ({
                                                                ...styles,
                                                                cursor: "pointer",
                                                                display: "block",
                                                                fontSize: "0.9rem",
                                                                width: "100%",
                                                                padding: "5px 12px",
                                                                boxSizing: "border-box",
                                                                borderBottom: data?.className ? "1px solid #8e8e8e" : "",
                                                            }),
                                                        }}
                                                    />


                                                </div>
                                                <div className="col-3 mt-2">
                                                    <label className="customLabelMargin font500">
                                                        Shape By
                                                    </label>
                                                    <Select
                                                        value={shapedefault}
                                                        //placeholder="Select Xaxis"
                                                        className={` select-template  `}
                                                        onChange={(event) => { setSelectedOptionRight(event, 'shapeby') }}
                                                        options={shapelist}
                                                        isSearchable={true}
                                                        classNamePrefix="selectDropdown"
                                                        aria-label="Default select example"
                                                        name="shapeby"
                                                        //hideSelectedOptions={true}
                                                        //value={selectedOptionxaxis}
                                                        styles={{
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                borderRadius: "0em 0.25em   0.25em 0em",

                                                            }),

                                                            option: (
                                                                styles,
                                                                { data, isDisabled, isFocused, isSelected }
                                                            ) => ({
                                                                ...styles,
                                                                cursor: "pointer",
                                                                display: "block",
                                                                fontSize: "0.9rem",
                                                                width: "100%",
                                                                padding: "5px 12px",
                                                                boxSizing: "border-box",
                                                                borderBottom: data?.className ? "1px solid #8e8e8e" : "",
                                                            }),
                                                        }}
                                                    />

                                                </div>
                                                <div className="col-3 mt-2">
                                                    <label className="customLabelMargin font500">
                                                        Color By
                                                    </label>
                                                    <Select
                                                        value={colordefault}
                                                        //placeholder="Select Xaxis"
                                                        className={` select-template  `}
                                                        onChange={(event) => { setSelectedOptionRight(event, 'colorby') }}
                                                        options={colorlist}
                                                        isSearchable={true}
                                                        classNamePrefix="selectDropdown"
                                                        aria-label="Default select example"
                                                        name="colorby"
                                                        //hideSelectedOptions={true}
                                                        //value={selectedOptionxaxis}
                                                        styles={{
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                borderRadius: "0em 0.25em   0.25em 0em",

                                                            }),

                                                            option: (
                                                                styles,
                                                                { data, isDisabled, isFocused, isSelected }
                                                            ) => ({
                                                                ...styles,
                                                                cursor: "pointer",
                                                                display: "block",
                                                                fontSize: "0.9rem",
                                                                width: "100%",
                                                                padding: "5px 12px",
                                                                boxSizing: "border-box",
                                                                borderBottom: data?.className ? "1px solid #8e8e8e" : "",
                                                            }),
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                            : null}
                                        {lineBlock ?
                                            <div className="row">
                                                <div className="col-3 mt-2">
                                                    <label className="customLabelMargin font500">
                                                        Select Xaxis
                                                    </label>
                                                    <Select
                                                        value={xaxisdefault}
                                                        //placeholder="Select Xaxis"
                                                        className={` select-template  `}
                                                        onChange={(event) => { setSelectedOptionRight(event, 'xaxis') }}
                                                        options={xaxislist}
                                                        isSearchable={true}
                                                        classNamePrefix="selectDropdown"
                                                        aria-label="Default select example"
                                                        name="xaxis"
                                                        //hideSelectedOptions={true}
                                                        //value={selectedOptionxaxis}
                                                        styles={{
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                borderRadius: "0em 0.25em   0.25em 0em",

                                                            }),

                                                            option: (
                                                                styles,
                                                                { data, isDisabled, isFocused, isSelected }
                                                            ) => ({
                                                                ...styles,
                                                                cursor: "pointer",
                                                                display: "block",
                                                                fontSize: "0.9rem",
                                                                width: "100%",
                                                                padding: "5px 12px",
                                                                boxSizing: "border-box",
                                                                borderBottom: data?.className ? "1px solid #8e8e8e" : "",
                                                            }),
                                                        }}
                                                    />


                                                </div>
                                                <div className="col-3 mt-2">
                                                    <label className="customLabelMargin font500">
                                                        Select Yaxis
                                                    </label>
                                                    <Select
                                                        value={yaxisdefault}
                                                        //placeholder="Select Xaxis"
                                                        className={` select-template  `}
                                                        onChange={(event) => { setSelectedOptionRight(event, 'yaxis') }}
                                                        options={yaxislist}
                                                        isSearchable={true}
                                                        classNamePrefix="selectDropdown"
                                                        aria-label="Default select example"
                                                        name="yasis"
                                                        //hideSelectedOptions={true}
                                                        //value={selectedOptionxaxis}
                                                        styles={{
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                borderRadius: "0em 0.25em   0.25em 0em",

                                                            }),

                                                            option: (
                                                                styles,
                                                                { data, isDisabled, isFocused, isSelected }
                                                            ) => ({
                                                                ...styles,
                                                                cursor: "pointer",
                                                                display: "block",
                                                                fontSize: "0.9rem",
                                                                width: "100%",
                                                                padding: "5px 12px",
                                                                boxSizing: "border-box",
                                                                borderBottom: data?.className ? "1px solid #8e8e8e" : "",
                                                            }),
                                                        }}
                                                    />


                                                </div>


                                            </div>
                                            : null}

                                    </div>
                                    <div className="col-2 pull-right pt-4 pl-3" >

                                        <button type="button" onClick={resetChart} className="btn  btn-xs switch-chart pull-right" id="submit-reset" style={{ margin: '0 10px', backgroundColor: '#ba6868', color: 'white' }} title="Reset Chart">
                                            <i className="fa fa-refresh"></i>   
                                        </button>
                                        <button onClick={() => generateLineChart(Graphdata)} className={`btn  btn-xs switch-chart pull-right  ${lineBlock ? 'btn-primary' : 'btn-grey'}`} key="line-chart" title="Switch to Line Chart" style={{ marginLeft: '8px' }}><i class="fa fa-line-chart"></i></button>
                                        <button onClick={() => generateSctteredChart(Graphdata)} className={`btn  btn-xs switch-chart pull-right ${scatterBlock ? 'btn-primary' : 'btn-grey'}`} key="scatter-plot" title="Switch to Scatter Plot"><i class="fa fa-area-chart"></i></button>
                                    </div>

                                </div>


                            </div>
                            {/* <div className="offset-1"></div> */}
                            <div className="col-12 viz-chart" id="style-3" >
                                {/* {scatterChart ? */}
                                {localStorage.getItem("lastselectedchart") === 'scatter' ?
                                    <>
                                        <div className="scatter-plot pb-2 pt-2 viz-option">
                                            <div className="scatter-plot">
                                                <div className="scatter-plot" id="scatter-plot">Shape By Legends</div>
                                                <div className="scatter-plot text-right" id="scatter-plot">
                                                    <div className="d-flex align-items-center flex-wrap">
                                                        {
                                                            shapewiseshape.map((item, index) => {
                                                                return (
                                                                    <div className="d-flex align-items-center mr-2" key={index}>
                                                                        <div style={{ width: '8px', height: '8px', backgroundColor: Object.values(item)[0] }} className="mr-1"></div>
                                                                        <div>
                                                                            {
                                                                                Object.values(item)[0] === 'star' &&
                                                                                <StarFill size={10} color="royalblue" className="mr-1" />
                                                                            }
                                                                            {
                                                                                Object.values(item)[0] === 'circle' &&
                                                                                <RecordFill size={12} color="royalblue" className="mr-1" />
                                                                            }
                                                                            {
                                                                                Object.values(item)[0] === 'square' &&
                                                                                <SquareFill size={10} color="royalblue" className="mr-1" />
                                                                            }
                                                                            {
                                                                                Object.values(item)[0] === 'triangle' &&
                                                                                <TriangleFill size={10} color="royalblue" className="mr-1" />
                                                                            }
                                                                            {
                                                                                Object.values(item)[0] === 'cross' &&
                                                                                <Plus size={10} color="royalblue" className="mr-1" />
                                                                            }
                                                                            {
                                                                                Object.values(item)[0] === 'diamond' &&
                                                                                <SuitDiamondFill size={10} color="royalblue" className="mr-1" />
                                                                            }

                                                                            {Object.keys(item)[0]}</div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="scatter-plot pb-2 pt-2 viz-option">
                                            <div className="scatter-plot">
                                                <div className="scatter-plot" id="scatter-plot">
                                                    Color by Legends
                                                </div>
                                                <div className="scatter-plot text-right" id="scatter-plot">
                                                    <div className="d-flex align-items-center flex-wrap">
                                                        {
                                                            colorwisecolrcode.map((item, index) => {
                                                                return (
                                                                    <div className="d-flex align-items-center mr-2" key={index}>
                                                                        <div style={{ width: '8px', height: '8px', backgroundColor: Object.values(item)[0] }} className="mr-1"></div>
                                                                        <div>{Object.keys(item)[0]}</div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    {/* <div class="d-flex">
                                                        <div class="d-inline-block"> 
                                                            <div style={{ width: '8px', height: '8px', backgroundColor: 'red' }} className="mr-1"> <span>kk</span></div>

                                                             
                                                        </div>
                                                        <div class="d-inline-block"><span style={{ width: '8px', height: '8px', backgroundColor: 'red' }} className="mr-1"></span> Your div 2 </div>
                                                    </div> */}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="pt-2"
                                        //onClick={handleGraphClick}
                                        >

                                            {/* {scatterData.map((entry, index) => (
                                                        <p>{index}
                                                        {COLORS[index % COLORS.length]} {SHAPE[index % SHAPE.length]}
                                                        </p>
                                                    // <Scatter name="A school" data={scatterData} fill={COLORS[index % COLORS.length]} shape={SHAPE[index % SHAPE.length]}>
                                                       
                                                    // </Scatter>
                                                     ))} */}
                                            <ResponsiveContainer width="100%" height={350}>
                                                <ScatterChart
                                                    // width="100%"
                                                    // height={400}
                                                    margin={{
                                                        top: 20,
                                                        right: 20,
                                                        bottom: 20,
                                                        left: 20,
                                                    }}
                                                >
                                                    <CartesianGrid />
                                                    <XAxis
                                                        type="number" interval={0}
                                                        name={xaxisdefault.label} dataKey={xaxisdefault.value}
                                                    //tick={<CustomizedAxisTick />} 
                                                    />
                                                    <YAxis type="number" name={yaxisdefault.label} dataKey={yaxisdefault.value} />
                                                    <Tooltip cursor={{ strokeDasharray: '3 3' }}
                                                        content={<CustomTooltipScatter />}
                                                        position={{ y: 100 }} />



                                                    {scatterData.length > 0 && scatterData.map((entry, index) => (

                                                        // <Scatter name="A school" data={scatterData[index]} shape={SHAPE[index % SHAPE.length]} index={index}>
                                                        //     <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} onMouseMove={() => setColor(index)} />

                                                        // </Scatter>
                                                        <Scatter name="A school" data={scatterData[index]}
                                                            shape={scatterData?.[index][0]['shape']}
                                                            index={index}
                                                        // activeDot={(props) => {
                                                        //     const isClicked = clickedPoint && clickedPoint.x === props.payload.name && clickedPoint.y === props.payload.value;
                                                        //     return { r: isClicked ? 8 : 5 };
                                                        // }}
                                                        // activeDot={(props) => {

                                                        //     return { r: clickedPoint ? 8 : 5 };
                                                        // }}

                                                        // shape={(props) => {
                                                        //     const { cx, cy, size, ...restProps } = props;
                                                        //     const isClicked = clickedPoint && clickedPoint.x === props.payload.name && clickedPoint.y === props.payload.value;
                                                        //     return (
                                                        //         <circle
                                                        //             cx={cx}
                                                        //             cy={cy}
                                                        //             r={isClicked ? 10 : 5} // Increase size if clicked
                                                        //             {...restProps}
                                                        //         />
                                                        //     );
                                                        // }}
                                                        >
                                                            <Cell key={`cell-${index}`} fill={scatterData?.[index][0]['color']} onMouseMove={() => setColor(index)} onClick={(event) => handlePointClickScatter(event, scatterData[index])} />

                                                        </Scatter>
                                                    ))}
                                                </ScatterChart>
                                            </ResponsiveContainer>


                                        </div>


                                        <div className="pt-2">
                                            <Tablefilterdata filterLineChartData={filterChartData} initialheaderstable={initialheaderstable} />
                                        </div>

                                    </>


                                    : null}

                                {/* {lineChart ? */}
                                {localStorage.getItem("lastselectedchart") === 'line' ?
                                    <>


                                        <div style={{
                                            //height: '50vh',
                                            height: `${maxLength}vh`,
                                            overflowX: 'auto', overflowY: 'auto',
                                        }} id="style-3">
                                            <div style={{
                                                width: lineData.length > 10 ? `${lineData.length * 10}vh` : "",
                                                //height: "43vh" 
                                                height: `${maxLength}vh`

                                            }}>
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <LineChart

                                                        data={lineData}
                                                        onClick={(event) => handlePointClick(event, lineData)}
                                                        margin={{
                                                            top: 15,
                                                            right: 30,
                                                            left: 20,
                                                            bottom: 5,
                                                        }}
                                                        //onMouseDown={handleMouseDown}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="name" fontSize={4}
                                                            height={maxLength === 50 ? 60 : 230}
                                                            tick={<CustomizedAxisTick />} interval={0} />
                                                        <YAxis />
                                                        <Tooltip content={<CustomTooltip />} position={{ y: 100 }} />
                                                        <Legend verticalAlign="top" align="right" />
                                                        {/* <Line type="monotone" name={xaxisdefault.label} dataKey={xaxisdefault.value} stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                                                        <Line type="monotone" name={yaxisdefault.label} 
                                                        dataKey={yaxisdefault.value} stroke="#2E6FD8" 
                                                        // activeDot={(props) => {
                                                        //     const isClicked = clickedPoint && clickedPoint.x === props.payload.name;
                                                        //     return <circle cx={props.cx} cy={props.cy} r={isClicked ? 8 : 5} fill={isClicked ? 'red' : 'blue'} />;
                                                        // }}
                                                         />
                                                    </LineChart>
                                                </ResponsiveContainer>
                                            </div>

                                        </div>


                                        <div className="pt-2">
                                            <Tablefilterdata filterLineChartData={filterChartData} initialheaderstable={initialheaderstable} />
                                        </div>
                                    </>


                                    : null}
                            </div>



                        </div>
                    </div>
                </div>
            </div >
            {loading && <Loader />}
        </>
    )
}
export default Visualisation;
