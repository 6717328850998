import React, { useContext, useEffect, useState } from "react";

import "./FileUpload.css";

import * as Icon from "react-bootstrap-icons";
import Select from "react-select";
import {
  fetchUploadTemplate,
  fetchUploadWorkflow,
  uploadExcelFile,
} from "../Services/UserServices";
import userContext from "../../user-context";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from "xlsx";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaHandshake, FaBoxOpen, FaFile, FaFileUpload } from "react-icons/fa";
import { LuWorkflow } from "react-icons/lu";
import { CgTemplate } from "react-icons/cg";
import { MdOutlineBatchPrediction, MdDataset } from "react-icons/md";
import { invalidChars } from "../../constants";
import {timeZones} from "../../constants";
import {specialCharRegex} from "../../constants";
import { useLocation,useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
const programOptions = [
  { value: "AMG", label: "AMG" },
  { value: "ABP", label: "ABP" },
  { value: "N/A", label: "N/A" },
];
const FileUpload = () => {
  localStorage.removeItem('selectedtemplate');
  localStorage.setItem("showbasicfilter", false)
  // localStorage.removeItem('showbasicfilter');
  localStorage.removeItem('type');
  //localStorage.removeItem('selectedtemplate');
  localStorage.removeItem('restorebasicfilter');
  localStorage.removeItem('restoreadvancefilter');
  localStorage.removeItem('selectedcheckdpartner');
  localStorage.removeItem('selectedcheckdworkflow');
  localStorage.removeItem('selectedcheckdstudy');
  localStorage.removeItem('selectedcheckdmaterial');
  localStorage.removeItem('selectedcheckdfile');
  localStorage.removeItem('selectedcheckddataset');
  localStorage.removeItem('selectedcheckdstatus');
  localStorage.removeItem('partnerlistsession');
  localStorage.removeItem('workflowlistsession');
  localStorage.removeItem('studylistsession');
  localStorage.removeItem('materiallistsession');
  localStorage.removeItem('filelistsession');
  localStorage.removeItem('datasetlistsession');
  localStorage.removeItem('statuslistsession');
  const [selectedFile, setSelectedFile] = useState(null);
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [workflowTypeList, setWorkflowList] = useState(null);
  const [selectedWorkflowType, setSelectedWorkflowType] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [materialId, setMaterialId] = useState("");
  const [studyId, setStudyId] = useState("");
  const [dataset, setDataset] = useState("");
  const [fileName, setFileName] = useState("");
  const [isSubmitClicked, toggleSubmitClicked] = useState(false);
  const [isFileValidation, toggleFileValidation] = useState(false);
  const [fileKey, setFileKey] = useState(1);
  const [templateRawList, setTemplateRawList] = useState([]);
  const [templateVersion, setTemplateVersion] = useState(0);
  const [isTemplateSelected, toggleTemplateSelected] = useState(false);
  const [isComingfromFileUpload, setIsComingfromFileUpload] = useState(false);
  const [dataFromReUpload, setDataFromReUpload] = useState(null);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [showErrorBox,toggleShowErrorBox] = useState(false);
  // const [errorMessage,  ] = useState("");
  const {
    blockData,
    setBlockData,
    selectedUserRole,
    setSelectedUserRole,
    loading,
    setLoading,
  } = useContext(userContext);
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const location = useLocation();
  const navigate=useNavigate();
  const duration = 15000;
  const sanitizeInput = (input) => {
    return input.replaceAll(/[&/\\#,+()$~%.^'":*?<>{}]/g, "");
  }
  useEffect(() => {
    localStorage.setItem("currentPath", location.pathname);
  }, []);
//  console.log('location',location)
  // useEffect(() => {
  //   if(location?.key==="default"){
  //     navigate({
  //       pathname: "/",
  //       state: "landing",
  //     }); 
  //   }
  // },[location?.key]);

 
  // window.onbeforeunload = function() {
  //   navigate({
  //     pathname: "/",
  //     state: "landing",
  //   });
  // }
  
  useEffect(() => {
    if(location?.state){
      setIsComingfromFileUpload(true);
      const reUploadDetails=location?.state?.fileDetails
      setDataFromReUpload(reUploadDetails);
 
    //   setFileKey(fileKey + 1);
    // setSelectedFile("");
    
    
    }
  },[]);
  useEffect(() => {
    if(dataFromReUpload){
 //console.log('dataFromReUpload',dataFromReUpload)
setSelectedTemplate( {label:dataFromReUpload?.templateName,value:dataFromReUpload?.templateId}  );
    setSelectedWorkflowType({label:dataFromReUpload?.workflowType,value:dataFromReUpload?.workflowType});
    setSelectedProgram({label:dataFromReUpload?.programId.split(" ")[0],value:dataFromReUpload?.programId.split(" ")[0]});
    setMaterialId(dataFromReUpload?.programId? dataFromReUpload?.programId.split(" ")[1]:'');
    setStudyId(dataFromReUpload?.studyId?dataFromReUpload?.studyId:'');
    setDataset(dataFromReUpload?.dataset?dataFromReUpload?.dataset:'');
    setFileName(dataFromReUpload?.fileName?dataFromReUpload?.fileName:'');
    setTemplateVersion(dataFromReUpload?.templateVersion);
    
    }


  },[dataFromReUpload]);
  useEffect(() => {
    let filenameString = `${
      selectedProgram
        ? selectedProgram.label !== "N/A"
          ? selectedProgram.label
          : ""
        : ""
    }${materialId ? materialId.toUpperCase() + "_" : ""}${studyId ? studyId.toUpperCase() + "_" : ""}${
      dataset ? dataset.toUpperCase() + "_" : ""
    }${user?.partner_name + "_"}${
      selectedTemplate?.label ? selectedTemplate.label : ""
    }`;

    //console.log("filenameString", filenameString);
    setFileName(filenameString);
  }, [
    studyId,
    dataset,
    materialId,
    selectedProgram,
    selectedTemplate,
    user?.partner_name,
  ]);
  // useEffect(() => {
  //   const beforeunloadHandle = () => {
  //     return true;
  //   };
  //   window.addEventListener("beforeunload", beforeunloadHandle);
  //   return () => {
  //     window.removeEventListener("removeEventListener", beforeunloadHandle);
  //   };
  // }, []);
  useEffect(() => {
    const fetchUploadTemplateList = () =>
      fetchUploadTemplate({
        user_id: user.user_id,
        role_id: user.roles[0].role_name,
        partner_id: user.partner_name,
      })
        .then((data) => {
          setTemplateRawList(data);
          let templateList = data.map((item) => ({
            value: item.template_id,
            label: item.template_name,
          }));
          if(templateList.length>2)
          {
            templateList[2].className = "borderBottomTemplateList";
          }
          

          setTemplateList(templateList);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 401) {
              console.log("initSSO: Token invalid - let's try again,token epired");
              window.localStorage.removeItem("hashToken");
              window.localStorage.removeItem("accessToken");
              window.open(window.location.origin + "/api/link", "_self");
            }
            // else if (error.response.status === 400) {
            //   toast.error("Unable to locate the resource. Please try after sometime or contact the system administrator by emailing your sponsor");
            // }
            else if (error.response.status === 403) {
              toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')
  
            }
            else {
  
              toast.error(
                "There was an error, Template names are not populated"
              );
  
            }
  
          } else {
            // Something happened in setting up the request that triggered an Error
            toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')
  
          }
          
        });
    fetchUploadTemplateList();

    
  }, []);

  const handleSelectTemplate = (e) => {
    toggleTemplateSelected(true);
    templateRawList.filter((item) => {
      if (item.template_id === e.value) {
        //console.log("item", item.template_version);
        setTemplateVersion(item.template_version);
      }
    });
    setSelectedWorkflowType(null);

    fetchUploadWorkflow(e.label)
      .then((data) => {
        setWorkflowList(data);
        setSelectedWorkflowType(data?.length === 1 ? data[0] : null);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 401) {
            console.log("initSSO: Token invalid - let's try again,token epired");
            window.localStorage.removeItem("hashToken");
            window.localStorage.removeItem("accessToken");
            window.open(window.location.origin + "/api/link", "_self");
          }
          // else if (error.response.status === 400) {
          //   toast.error("Unable to locate the resource. Please try after sometime or contact the system administrator by emailing your sponsor");
          // }
          else if (error.response.status === 403) {
            toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')

          }
          else {
  
              toast.error(
                "There was an error, Workflow names are not populated"
              );
          }

        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')

        }
        
      });

    setSelectedTemplate(e);
  };
  const handleSelectWorkflowType = (e) => {
    setSelectedWorkflowType(e);
  };
  const handleSelectProgram = (e) => {
    setSelectedProgram(e);
  };
  const handleFileChange = (event) => {
    toggleFileValidation(true);
    setSelectedFile(event.target.files[0]);
    validateUploadedFile(event.target.files[0]);
  };
  const validateUploadedFile = (file) => {
    let allowedExtensions = /(\.xlsx)$/i;
    const maxSizeInBytes = 15 * 1024 * 1024; // 15MB
    if (!allowedExtensions.exec(file.name)) {
      toast.error("Please upload file with .xlsx extension");
      toggleFileValidation(false);
      return false;
    }
    if (file.size === 0) {
      toast.error("Uploaded file is empty");
      toggleFileValidation(false);
      return false;
    }
    if (file.size >maxSizeInBytes) {
      toast.error('Warning: File size exceeds 15MB');
      // toggleFileValidation(false);
      // return false;
    }
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Sheetname validation */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const excelData = XLSX.utils.sheet_to_json(ws, { header: 1 });

     // console.log("excelData", excelData);

      let tabcheck = checkTabXLSX(wb.SheetNames, wb);
      // console.log(tabcheck)
      // console.log(timeZones)
      toggleFileValidation(tabcheck);
    };
  };
  const checkTabXLSX = (SheetNames, wb) => {
    // let tabCheck = true;
    //  ("");
    let errorMessage = "";
    if (SheetNames.length === 0) {
      errorMessage = errorMessage += "No worksheet found in the uploaded file";
      return false;
    } else {
      if (
        0 in SheetNames &&
        SheetNames[0].toLowerCase() !== "Data".toLowerCase()
      ) {
        // toast.error(
        //   "First worksheet should always be 'Data'"
        // );
        // return false;

        errorMessage += "- First worksheet should always be 'Data'.\n ";
      } 
       if (
        1 in SheetNames &&
        SheetNames[1].toLowerCase() !== "Supplementary Data".toLowerCase()
      ) {
        // toast.error(
        //   "Supplementary Data does not exists in this template"
        // );
        // return false;
        errorMessage +=
          "- Second worksheet should always be 'Supplementary Data'.\n ";
      } 
      //  if (
      //   0 in SheetNames &&
      //   SheetNames[0].toLowerCase() !== "Data".toLowerCase() &&
      //   1 in SheetNames &&
      //   SheetNames[1].toLowerCase() !== "Supplementary Data".toLowerCase()
      // ) {
      //   // toast.error(
      //   //   "First worksheet should always be 'Data' and second worksheet should always be 'Supplementary Data'"
      //   // )
      //   // return false;
      //   errorMessage +=
      //     "First worksheet should always be 'Data' and second worksheet should always be 'Supplementary Data'.\n ";
      // }

      if (0 in SheetNames && SheetNames[0] === "Data") {
        const excelData = XLSX.utils.sheet_to_json(
          wb.Sheets[wb.SheetNames[0]],
          { header: 1 }
        );
        if (
          (excelData[0] && excelData[0].length === 0) ||
          (excelData[1] && excelData[1].length === 0)
        ) {
          // toast.error("Data tab should not be empty");
          // return false;
          errorMessage += "- Data tab should not be empty.\n ";
        }

        for (let i = 0; i < excelData.length; i++) {
          //console.log(data[i])
            if (excelData[i] === undefined) {
              continue;
            }
            
           if (specialCharRegex.test(excelData[i])) 
            {
              //console.log('special char')
              errorMessage += "- The updated file contains characters '|' and/or '~'.Please remove these characters and upload again\n ";
            }
          }
      }
      if (1 in SheetNames && SheetNames[1] === "Supplementary Data") {
        const excelSuppData = XLSX.utils.sheet_to_json(
          wb.Sheets[wb.SheetNames[1]],
          { header: 1 }
        );
        if (excelSuppData && excelSuppData.length === 0) {
          // toast.error("Supplementary Data tab should not be empty");
          // return false;
          errorMessage += "- Supplementary Data tab should not be empty.\n ";
        }
        if (excelSuppData[0] && excelSuppData[0].length !== 0) {
          if (
            excelSuppData[0][0].toLowerCase() !==
            "Template Version Number".toLowerCase()
          ) {
            // toast.error(
            //   "Supplementary Data tab should have 'Template Version Number' column in first row"
            // );
            // return false;
            errorMessage +=
              "- Supplementary Data tab should have 'Template Version Number' column in first row.\n ";
          }
          if (isNaN(excelSuppData[0][1])) {
            // toast.error("Template Version Number should be a number");
            // return false;
            errorMessage += "- Template Version Number should be a number.";
          } else if (parseInt(excelSuppData[0][1]) !== templateVersion) {
            errorMessage +=
              "- Template Version Number should be " + templateVersion + " for the uploaded file.\n ";
          }

          for (let i = 0; i < excelSuppData.length; i++) {
          
            if(excelSuppData[i][0].toLowerCase() === 'time zone'){
            if(excelSuppData[i][1] !== undefined){
              if(!timeZones.includes(excelSuppData[i][1].trim())){
               // console.log(excelSuppData[i][1])
                errorMessage += "- Incorrect Timezone format provided in uploaded file. Please reach out to your Amgen contact to rectify this error.\n";
              }
            }
              else{
                errorMessage += "- Timezone column cannot be empty. Please upload the file again with the Timezone values populated in all rows.\n";
              }

            }
           
           if (specialCharRegex.test(excelSuppData[i][1])) 
            {
              //console.log('special char')
              errorMessage += "- The updated file contains characters '|' and/or '~'.Please remove these characters and upload again\n ";
            }
            
          }
        }
      }

      if (errorMessage.length > 0) {
        //console.log("errorMessage", errorMessage);
        toast.error(
          (t) => (
            <span>
              { errorMessage}
              <button
                onClick={() => toast.dismiss(t.id)}
                className="close-toast-button"
                type="button"
              >
                ×
              </button>
            </span>
          ),
          { duration }
        );
        // toast.error(errorMessage);
        return false;
      }
      return true;
    }
  };

  const blockSpecialChar = (event) => {
    const invalidCharList = new Set(invalidChars);
    invalidCharList.add("_");
    if (invalidCharList.has(event.key)) {
      event.preventDefault();
    }
  };
  const handleReset = () => {
    setFileKey(fileKey + 1);
    setSelectedFile("");
    setSelectedTemplate(null);
    setSelectedWorkflowType(null);
    setSelectedProgram(null);
    setMaterialId("");
    setStudyId("");
    setDataset("");
    setFileName(user?.partner_name + "_");
    toggleSubmitClicked(false);
    toggleFileValidation(false);
    toggleTemplateSelected(false);
    setTemplateVersion(0);
    setWorkflowList([]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    toggleSubmitClicked(true);
    setLoading(true);

    if (
      !selectedTemplate ||
      !selectedWorkflowType ||
      !selectedProgram ||
      !materialId ||
      !selectedFile
    ) {
      toast.error("Please fill all the mandatory fields");
      setLoading(false);
      return;
    }
    if (sanitizeInput(fileName).length > 218) {
      toast.error("File name should not exceed 218 characters");
      setLoading(false);
      return;
    }
    
    // if(!selectedFile.name.includes(selectedTemplate?.label))
    // {
    //   toast.error("Please upload a file for selected template");
    //   setLoading(false);
    //   return false;
    // }

    if (isFileValidation) {
      let formData = new FormData();
      let fileField = document.querySelector(".uploadFile");

      formData.append("file", fileField.files[0]);
      let inputObj = {
        templateName: selectedTemplate?.label,
        workflowType: selectedWorkflowType?.label,
        programId:
          (selectedProgram?.label !== "N/A"
            ? selectedProgram?.label + " "
            : "") + materialId.toUpperCase(),
        studyId: studyId.toUpperCase(),
        dataset: dataset.toUpperCase(),
        fileName: fileName,
        userId: user.user_id,
        partnerName: user.partner_name,
        userRole: user.roles[0].role_id.toString(),
        template_version: templateVersion,
        reUploadFlag:isComingfromFileUpload
      };
      formData.append("inputs", JSON.stringify(inputObj));
      //console.log("formData", JSON.stringify(inputObj));
      uploadExcelFile(formData)
        .then((data) => {
          if (data.status === "success") {
            setLoading(false);
            handleReset();
            toggleFileValidation(true);
            toast.success(sanitizeInput(data?.result));
          } else {
            setLoading(false);
            toggleFileValidation(true);
            setApiErrorMessage(data?.result);
            toggleShowErrorBox(true);
          setTimeout(() => {
            toggleShowErrorBox(false);
          }, 15000);
            
          }
        })
        .catch((error) => {
          setLoading(false);
          toggleFileValidation(true);
          //console.log("error", error);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 401) {
              console.log("initSSO: Token invalid - let's try again,token epired");
              window.localStorage.removeItem("hashToken");
              window.localStorage.removeItem("accessToken");
              window.open(window.location.origin + "/api/link", "_self");
            }
            else if (error.response.status === 403) {
                    
                toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')
    
            }
          else {
  
              toast.error(
                "There was an error in uploading the file. Please try again or contact administrator."
              );
            }
  
          } else {
            // Something happened in setting up the request that triggered an Error
            toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')
  
          }
          // toast.error(
          //   "There was an error in uploading the file. Please try again or contact administrator."
          // )
          // setApiErrorMessage("There was an error in uploading the file. Please try again or contact administrator.");
          // toggleShowErrorBox(true);
          // setTimeout(() => {
          //   toggleShowErrorBox(false);
          // }, 15000);
        });
    } else {
      setLoading(false);
      toast.error("Please upload a valid file");
    }
  };

  return (
    <React.Fragment>
      <form
        className="row justify-content-center"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="col-7 mt-2">
          <div className="col-12 px-0">
            <label className="customLabelMargin">Partner</label>
            <br />
            <div className="row">
              <div className="input-group-text ml-3">
                <FaHandshake color="white" />
              </div>
              <div className="col-11 px-0">
                <input
                  name="partner"
                  type="text"
                  className="col-12 form-control"
                  value={user?.partner_name}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="col-12 mt-2 px-0">
            <label className="customLabelMargin">
              Template Name <span className={`mandetoryfield`}>*</span>
            </label>
            <br />
            <div className="row">
              <div className="input-group-text ml-3">
                <CgTemplate color="white" />
              </div>
              <div className="col-11 px-0">
                <Select
                  options={templateList}
                  className={` select-template  `}
                  isSearchable={true}
                  // menuIsOpen={true}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderRadius: "0em 0.25em   0.25em 0em",
                      borderColor:
                        isSubmitClicked && !selectedTemplate
                          ? "red"
                          : "#ced4da",
                      fontSize: "0.9rem",
                    }),

                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => ({
                      ...styles,
                      cursor: "pointer",
                      display: "block",
                      fontSize: "0.9rem",
                      width: "100%",
                      padding: "5px 12px",
                      boxSizing: "border-box",
                      borderBottom: data?.className ? "1px solid #8e8e8e" : "",
                    }),
                  }}
                  classNamePrefix="selectDropdown"
                  aria-label="Default select example"
                  name="templateName"
                  onChange={handleSelectTemplate}
                  value={selectedTemplate || ""}
                  placeholder="Select Template Name"
                  isDisabled={isComingfromFileUpload}
                />
              </div>
            </div>
          </div>
          <div className="col-12 mt-2 px-0">
            <label className="customLabelMargin">
              Workflow Type<span className={`mandetoryfield`}>*</span>
            </label>
            <br />
            <div className="row">
              <div className="input-group-text ml-3">
                <LuWorkflow color="white" />
              </div>
              <div className="col-11 px-0">
                <Select
                  options={workflowTypeList || []}
                  isSearchable={true}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderRadius: "0em 0.25em   0.25em 0em",
                      borderColor:
                        isSubmitClicked  && !selectedWorkflowType
                          ? "red"
                          : "#ced4da",
                      fontSize: "0.9rem",
                    }),

                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => ({
                      ...styles,
                      cursor: "pointer",
                      display: "block",
                      fontSize: "0.9rem",
                      width: "100%",
                      padding: "5px 12px",
                      boxSizing: "border-box",
                      
                    }),
                  }}
                  classNamePrefix="selectDropdown"
                  aria-label="Default select example"
                  name="workflowType"
                  onChange={handleSelectWorkflowType}
                  value={selectedWorkflowType}
                  placeholder="Select Workflow Type"
                  isDisabled={isComingfromFileUpload}
                />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <label className="customLabelMargin">
                Program/Material ID <span className={`mandetoryfield`}>*</span>
              </label>
              <br />
              <div className="row">
                <div className="input-group-text ml-3">
                  <FaBoxOpen color="white" />
                </div>
                <div className="col-10 pl-0">
                  <Select
                    options={programOptions.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))}
                    isSearchable={true}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: "0em 0.25em   0.25em 0em",
                        borderColor:
                          isSubmitClicked && !selectedProgram
                            ? "red"
                            : "#ced4da",
                        fontSize: "0.9rem",
                      }),

                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => ({
                        ...styles,
                        cursor: "pointer",
                        display: "block",
                        fontSize: "0.9rem",
                        width: "100%",
                        padding: "5px 12px",
                        boxSizing: "border-box",
                      }),
                    }}
                    classNamePrefix="selectDropdown"
                    aria-label="Default select example"
                    name="program"
                    onChange={handleSelectProgram}
                    value={selectedProgram}
                    placeholder="Select Program/Material ID"
                    isDisabled={isComingfromFileUpload}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <label className="customLabelMargin">{""}</label>
              <br />
              <div className="row mt-2 ">
                <div className="col-12 ">
                  <input
                    name="materialId"
                    type="text"
                    className={`col-12 form-control ${
                      isSubmitClicked && !materialId
                        ? "border border-danger"
                        : ""
                    }`}
                    value={materialId.toUpperCase()}
                    onChange={(e) => setMaterialId(e.target.value)}
                    onKeyDown={blockSpecialChar}
                    placeholder="Program/Material ID No. eg.: 176"
                    disabled={isComingfromFileUpload}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mt-2 ">
            <div className="col-4 ">
              <label className="labelPadding">
                Workflow Type<span className={`mandetoryfield`}>*</span>
              </label>
              <div className="row ml-0">
                <div className="input-group-text ">
                  <LuWorkflow color="white" />
                </div>
                <div className="col-10 pl-0">
                  <Select
                    options={workflowTypeList || []}
                    isSearchable={true}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: "0em 0.25em   0.25em 0em",
                        borderColor:
                          isSubmitClicked && !selectedTemplate
                            ? "red"
                            : "#ced4da",
                        fontSize: "0.9rem",
                      }),

                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => ({
                        ...styles,
                        cursor: "pointer",
                        display: "block",
                        fontSize: "0.9rem",
                        width: "100%",
                        padding: "5px 12px",
                        boxSizing: "border-box",
                      }),
                    }}
                    classNamePrefix="selectDropdown"
                    aria-label="Default select example"
                    name="workflowType"
                    onChange={handleSelectWorkflowType}
                    value={selectedWorkflowType}
                    placeholder="Select Workflow Type"
                  />
                </div>
              </div>
            </div>
            <div className="col-4 px-0">
              <label className="labelPadding">
                Program/Material ID <span className={`mandetoryfield`}>*</span>
              </label>
              <br />
              <div className="row ml-0">
                <div className="input-group-text">
                  <FaBoxOpen color="white" />
                </div>
                <div className="col-10 pl-0">
                  <Select
                    options={programOptions}
                    isSearchable={true}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: "0em 0.25em   0.25em 0em",
                        borderColor:
                          isSubmitClicked && !selectedTemplate
                            ? "red"
                            : "#ced4da",
                        fontSize: "0.9rem",
                      }),

                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => ({
                        ...styles,
                        cursor: "pointer",
                        display: "block",
                        fontSize: "0.9rem",
                        width: "100%",
                        padding: "5px 12px",
                        boxSizing: "border-box",
                      }),
                    }}
                    classNamePrefix="selectDropdown"
                    aria-label="Default select example"
                    name="program"
                    onChange={handleSelectProgram}
                    value={selectedProgram}
                    placeholder="Select Program/Material ID"
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <label className='customLabelMargin'>{""}</label>
              <br />
              <div className="row mt-2 ">
                <div className="col-12 ">
                  <input
                    name="materialId"
                    type="text"
                    className={`col-12 form-control ${
                      isSubmitClicked && !materialId
                        ? "border border-danger"
                        : ""
                    }`}
                    value={materialId.toUpperCase()}
                    onChange={(e) => setMaterialId(e.target.value)}
                    onKeyDown={blockSpecialChar}
                    placeholder="Program/Material ID No. eg.: 176"
                  />
                </div>
              </div>
            </div>
          </div> */}

          <div className="row mt-2">
            <div className="col-6">
              <label className="customLabelMargin">Batch/Study ID</label>
              <br />
              <div className="row">
                <div className="input-group-text ml-3">
                  <MdOutlineBatchPrediction color="white" />
                </div>
                <div className="col-10 pl-0">
                  <input
                    name="studyId"
                    type="text"
                    className="col-12 form-control"
                    value={studyId?.toUpperCase()}
                    onChange={(e) => setStudyId(e.target.value)}
                    onKeyDown={blockSpecialChar}
                    placeholder="Enter Batch/Study ID"
                    disabled={isComingfromFileUpload}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <label className="customLabelMargin">Dataset</label>
              <br />
              <div className="row">
                <div className="input-group-text ml-3">
                  <MdDataset color="white" />
                </div>
                <div className="col-10 px-0">
                  <input
                    name="dataset"
                    type="text"
                    className="col-12 form-control"
                    value={dataset?.toUpperCase()}
                    onChange={(e) => setDataset(e.target.value)}
                    onKeyDown={blockSpecialChar}
                    placeholder="Enter Dataset. eg.: 3MONTHS"
                    disabled={isComingfromFileUpload}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 px-0 mt-2">
            <label className="customLabelMargin">
              File Name
              <span
                className="ml-2 opacity"
                data-tip
                data-tooltip-id="fileName_tooltip"
                style={{ color: "#8e8e8e" }}
              >
                <i className="fa fa-info-circle" aria-hidden="true"></i>
              </span>
              <ReactTooltip
                className="reactTooltip font-size-12"
                id="fileName_tooltip"
                place="right-start"
                effect="solid"
                multiline={true}
              >
                The file name should not exceed 218 characters.<br/>
                File Name Format:- {`<Program/Material ID>_<Batch/Study ID>_<Dataset>_<Partner>_<Template Name>`}
              </ReactTooltip>
            </label>
            <br />
            <div className="row">
              <div className="input-group-text ml-3">
                <FaFile color="white" />
              </div>
              <div className="col-11 px-0">
                <input
                  name="fileName"
                  type="text"
                  className="col-12 form-control"
                  disabled
                  value={fileName?.toUpperCase()}
                />
              </div>
            </div>
          </div>
          <div className="col-12 px-0 mt-2">
            <label className="customLabelMargin">
              File<span className={`mandetoryfield`}>*</span>{" "}
              <span
                className="ml-1 opacity"
                data-tip
                data-tooltip-id="file_tooltip"
                style={{ color: "#8e8e8e" }}
              >
                <i className="fa fa-info-circle" aria-hidden="true"></i>
              </span>
              <ReactTooltip
                className="reactTooltip font-size-12"
                id="file_tooltip"
                place="right-start"
                effect="solid"
                multiline={true}
                // style={{ width: "150%" }}
              >
                Please upload only .xlsx file.<br/> Please select the template first to enable file uploading
              </ReactTooltip>
            </label>
            <br />
            <div className="row">
              <div className="input-group-text ml-3">
                <FaFileUpload color="white" />
              </div>
              <div className="col-11 px-0">
                <input
                  className={` col-12 form-control uploadFile file p-0 ${
                    isSubmitClicked && !selectedFile
                      ? "border border-danger"
                      : "#ced4da"
                  }`}
                  type="file"
                  id="formFile"
                  key={fileKey}
                  onChange={(event) => handleFileChange(event)}
                  disabled={ isComingfromFileUpload?false:!isTemplateSelected}
                />
              </div>
              
            </div>
          </div>
        </div>
        <div className="offset-8 col-4 mt-2">
          
          <button type="submit" className="btn btn-primary ">
            Submit
          </button>
          <button
            type="reset"
            className="btn btn-secondary ml-3"
            onClick={handleReset}
          >
            Reset
          </button>
          <Toaster
            position="top-right"
            reverseOrder={false}
            toastOptions={{
              className: "",

              error: {
                icon: "❗",
              },
            }}
          />
        </div>
        {showErrorBox && <div className="errorBox">❗ {apiErrorMessage}</div>}
      </form>
    </React.Fragment>
  );
};

export default FileUpload;
