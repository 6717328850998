import React from "react";
import { useState, useRef, useEffect, useMemo, useCallback, useContext } from "react";

import toast, { Toaster } from "react-hot-toast";
import { fetchFileDetails } from "../Services/UserServices";
import { AgGridReact } from 'ag-grid-react';
import Accounttemplatestyle from '../Template/Addtemplate.module.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import CustomNoRowsOverlay from './customNoRowsOverlay';
const ShowDQMdetails = ({ showtem, setshowDQM, dqmdata, type }) => {
  const gridRef = useRef();
  //const [show, showDQMtype] = useState(false);
  const [rowData, setRowData] = useState([])
  const [errormessage, setErrormessage] = useState("");
  const [show, setShow] = useState(false);
  const [supplementData, setSupplementData] = useState([]);
  const [datatab, setDataTabDetails] = useState([]);
  const [dynamicHeaders, setdynamicHeaders] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    setShow(showtem);
  }, [showtem])


  const handleClose = (e) => {
    //setShow(false);
    //console.log(showtem)
    setshowDQM(prev => !prev)

  };
  let onSelect = (index) => {
    //alert(index)
    setTabIndex(index)

  }

  //console.log(dqmdata['requestId'])
  const onGridReady = useCallback((params) => {
    fetchFileData(dqmdata['action_id'].toString())
  }, []);

  const fetchFileData = (action_id) => {
    //console.log(type)
    if (type === 'viewdqm') {
      fetchFileDetails(action_id, 'Data').then((datas) => {

        // console.log(datas?.['result']['dataFile'])
        if ('result' in datas['data'] && datas['data']['status'] === 'success') {
          const convertedData = datas['data']?.['result']['metadataFile'].map(([key, value]) => ({ key, value }));
          setSupplementData(convertedData)

          const headers = datas['data'].result.dataFile[0];
          const columnDefs = headers.map((header) => {

            return { headerName: header, field: header, tooltipField: header };
          });
          //console.log(columnDefs)
          setdynamicHeaders(columnDefs);
          const data = datas['data'].result.dataFile.slice(1);

          const datatab = data.map(row => {
            let obj = {};
            row.forEach((item, index) => {
              obj[headers[index]] = item;
            });
            return obj;
          });
          //console.log(datatab)
          setDataTabDetails(datatab)
        }
        else {
          setErrormessage(datas['data']?.message)

        }
        // var dqmdata = datas;
        // setRowData(dqmdata);
        //console.log(dqmdata)
      })
        .catch(error => {
          //console.log(error)
          //setErrormessage(error.toString())
          //toast.error(error.toString())
          //console.error('There was an error!', error);

          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 401) {
              console.log("initSSO: Token invalid - let's try again,token epired");
              window.localStorage.removeItem("hashToken");
              window.localStorage.removeItem("accessToken");
              window.open(window.location.origin + "/api/link", "_self");
            }

            else if (error.response.status === 403) {
              toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')

            }
            else {
              
              setErrormessage(error.toString());

            }

          } else {
            // Something happened in setting up the request that triggered an Error
            toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')

          }

        });

    }
    else {
      fetchFileDetails(action_id, 'DQM').then((datas) => {

        // console.log(datas?.['result']['dataFile'])
        if ('result' in datas['data'] && datas['data']['status'] === 'success') {
          // const convertedData = datas?.['result']['metadataFile'].map(([key, value]) => ({ key, value }));
          // setSupplementData(convertedData)

          // const headers = datas.result.dataFile[0];
          // const columnDefs = headers.map((header) => {

          //   return { headerName: header, field: header, tooltipField: header };
          // });
          // console.log(columnDefs)
          // setdynamicHeaders(columnDefs);
          // const data = datas.result.dataFile.slice(1);

          // const datatab = data.map(row => {
          //   let obj = {};
          //   row.forEach((item, index) => {
          //     obj[headers[index]] = item;
          //   });
          //   return obj;
          // });
          // console.log(datatab)
          //setDataTabDetails(datatab)
        }
        // var dqmdata = datas;
        // setRowData(dqmdata);
        //console.log(dqmdata)
      })
        .catch(error => {
          setErrormessage(error.toString())
          //toast.error(error.toString())
          //console.error('There was an error!', error);

        });

    }


  }

  const [columnDefs] = useState(dynamicHeaders)

  // const [columnDefs] = useState([
  //   { headerName: 'Tab', field: 'tab' },
  //   { headerName: 'Column Name', field: 'columnName', tooltipField: 'columnName' },
  //   { headerName: 'Data Type', field: 'dataType', width: 100, maxWidth: 135 },
  //   {
  //     headerName: 'Null Check', field: 'nullCheck', width: 100, maxWidth: 100, valueFormatter: (params) => {

  //       if (params.value === 'true') {
  //         return 'YES'
  //       }
  //       else {
  //         return 'NO'
  //       }
  //       //return '£' + params.value;
  //     }
  //   },
  //   {
  //     headerName: 'Data Type Name', field: 'dataTypeCheck', width: 100, maxWidth: 120, valueFormatter: (params) => {

  //       if (params.value === 'true') {
  //         return 'YES'
  //       }
  //       else {
  //         return 'NO'
  //       }
  //       //return '£' + params.value;
  //     }
  //   },
  //   {
  //     headerName: 'Visualization', field: 'includeInVisualizationFilter', width: 100, maxWidth: 150,
  //     valueFormatter: (params) => {

  //       if (params.value === 'true') {
  //         return 'YES'
  //       }
  //       else {
  //         return 'NO'
  //       }
  //       //return '£' + params.value;
  //     }
  //   },
  //   {
  //     headerName: 'X-axis', field: 'includeInXaxisSelection', width: 100, maxWidth: 100,
  //     valueFormatter: (params) => {

  //       if (params.value === 'true') {
  //         return 'YES'
  //       }
  //       else {
  //         return 'NO'
  //       }
  //       //return '£' + params.value;
  //     }
  //   },
  //   {
  //     headerName: 'Y-axis', field: 'includeInYaxisSelection', width: 100, maxWidth: 100, valueFormatter: (params) => {

  //       if (params.value === 'true') {
  //         return 'YES'
  //       }
  //       else {
  //         return 'NO'
  //       }
  //       //return '£' + params.value;
  //     }
  //   },
  //   {
  //     headerName: ' Colour', field: 'includeInColorByOption', width: 100, maxWidth: 100,
  //     valueFormatter: (params) => {

  //       if (params.value === 'true') {
  //         return 'YES'
  //       }
  //       else {
  //         return 'NO'
  //       }
  //       //return '£' + params.value;
  //     }
  //   },
  //   {
  //     headerName: 'Shape', field: 'includeInShapeByOption', width: 100, maxWidth: 100,
  //     valueFormatter: (params) => {

  //       if (params.value === 'true') {
  //         return 'YES'
  //       }
  //       else {
  //         return 'NO'
  //       }
  //       //return '£' + params.value;
  //     }
  //   },


  // ])



  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      // minWidth: 100,
      // initialWidth: 200,
      // wrapHeaderText: true,
      // autoHeaderHeight: true,
      // width: 170,
      // resizable: true,
      // wrapText: true,
      // // autoHeight: true,
      // cacheQuickFilter: true,

      cacheQuickFilter: true,
      flex: 1,

      sortable: true,
      color: "red",

      wrapHeaderText: true,
      suppressMovable: true,
      autoHeaderHeight: true,
      //     wrapText: true,
      //    autoHeight: true,
      unSortIcon: true,


    };
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    // gridOptions.api.setQuickFilter(
    //   document.getElementById('filter-text-box1').value
    // );
    gridRef.current.api.setQuickFilter(
      document.getElementById('filter-text-box1').value
    );
    // Check if there are any rows after applying the filter
    const filteredRowCount = gridRef.current.api.getDisplayedRowCount();
    // If there are no rows, show the no rows overlay
    if (filteredRowCount === 0) {
      gridRef.current.api.showNoRowsOverlay();
    } else {
      gridRef.current.api.hideOverlay();
    }

  }, []);

  const resetSearch = () => {
    document.getElementById('filter-text-box1').value = ''
    onFilterTextBoxChanged()
  }

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => 'No rows found at: ' + new Date().toLocaleTimeString(),
    };
  }, []);
  return (
    <>

      {showtem ? (
        <div
          className={"modal fade bd-example-modal-lg" + (show ? " show d-block" : " d-none")}
          // tabIndex="-1"
          role="dialog"
          style={{
            position: "fixed",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            background: "rgb(182 184 202/0.5)",
            //opacity: "0.5",
            paddingTop: "4%",
            zIndex: 9999
          }}
        >
          <div className="modal-dialog modal-xl modal-dialog-recenterfile" role="document" >
            <div className="modal-content" style={{ opacity: "1" }}>
              <div className="modal-header">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-7 col-lg-7 col-sm-11 pb-0" style={{ minHeight: 30 }}>
                      <h5 className="modal-title" style={{ fontSize: '14px', wordBreak: 'break-word' }}>{dqmdata?.['fileName']}</h5>
                    </div>
                    {
                      tabIndex === 0 &&
                      <div className="col-md-4 col-lg-4 col-sm-12 pb-0 text-center" style={{ minHeight: 30 }}>


                        <div className="input-group searchBox" style={{ width: 'auto', border: 'transparent' }}>
                          <div className="input-group-prepend border-right-0">
                            <div
                              className="input-group-text bg-transparent "
                              id="btnGroupAddon2"
                            >
                              <i className="fa fa-search font-size-14"></i>
                            </div>
                          </div>
                          <input
                            className="form-control font-size-14 "
                            id="filter-text-box1"
                            type="search"
                            placeholder="Search..."
                            onInput={onFilterTextBoxChanged}
                          />
                          <button type="button" onClick={resetSearch} class="btn bg-transparent" style={{ marginLeft: '-40px', zIndex: 100 }} >
                            <i class="fa fa-times"></i>
                          </button>
                        </div>
                      </div>
                    }
                    {
                      tabIndex === 1 &&
                      <div className="col-md-4 col-lg-4 col-sm-12 pb-0 text-center" style={{ minHeight: 30 }}></div>
                    }
                    <div className="col-md-1 col-lg-1 col-sm-1" >
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleClose}
                        style={{
                          position: 'absolute',
                          // top: '10px',
                          right: '10px',
                          background: 'none',
                          border: 'none',
                          fontSize: '20px',
                          cursor: 'pointer',
                        }}
                      >
                        <span aria-hidden="true"><FontAwesomeIcon icon={faClose} size="lg" /></span>
                      </button>
                    </div>


                  </div>
                </div>




                {/* <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                   style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            background: 'none',
                            border: 'none',
                            fontSize: '20px',
                            cursor: 'pointer',
                          }}
                >
                  <span aria-hidden="true"><FontAwesomeIcon icon={faClose} size="lg" /></span>
                </button> */}
              </div>
              <div className="modal-body">
                <div className="row">

                  <div className="col-12 pb-1 ">
                    <Tabs onSelect={onSelect} >
                      <TabList>
                        <Tab ><FontAwesomeIcon icon={faSquareCheck} size="sm" /><span className="tabspace">Data</span></Tab>
                        <Tab ><FontAwesomeIcon icon={faListUl} size="sm" /><span className="tabspace">Supplementary Data</span></Tab>
                      </TabList>

                      <TabPanel>
                        <div   >
                          <div style={{ height: '100%', width: 'auto' }}>
                            <div
                              className="ag-theme-alpine datatable"
                              style={{
                                height: '450px',
                                width: 'auto'
                              }}
                            >
                              <AgGridReact

                                columnDefs={dynamicHeaders}
                                rowData={datatab}
                                defaultColDef={defaultColDef}
                                animateRows={true}
                                onGridReady={onGridReady}
                                pagination={true}
                                //rowHeight={80}
                                paginationAutoPageSize={true}
                                cacheQuickFilter={true}
                                ref={gridRef}
                                noRowsOverlayComponent={CustomNoRowsOverlay}
                                noRowsOverlayComponentParams={noRowsOverlayComponentParams}

                              >
                              </AgGridReact>
                            </div>


                          </div>

                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div  >
                          <table className="table table-bordered  table-striped table-responsive" >
                            <thead style={{ background: '#2262A5 0% 0% no-repeat padding-box', position: 'sticky', top: 0, zIndex: 1, color: '#FFFF', fontSize: '13px' }}>
                              <tr className="text-center">

                                <th>Column Name</th>
                                <th>Value</th>

                              </tr>
                            </thead>
                            <tbody className=" text-center bodyscroll" style={{ fontWeight: 500 }} id="style-3">
                              {supplementData.map((data, index) => (
                                <tr key={index}>

                                  <td>{data.key}</td>
                                  <td style={{ wordBreak: 'break-word' }}>{data.value}</td>

                                </tr>
                              ))}
                            </tbody>
                          </table>

                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>


                </div>


                {errormessage &&
                  <span className={` ${Accounttemplatestyle.mandetoryfield}`}>{errormessage}</span>
                }
                <Toaster
                  position="top-right"
                  reverseOrder={false}
                  toastOptions={{
                    className: "",
                    duration: 5000,
                    error: {
                      icon: "❗",
                      style: {
                        color: 'red',
                      },
                    },
                    success: {
                      duration: 5000,
                      style: {
                        color: 'green',
                      },
                    },
                  }}
                />



              </div>

            </div>
          </div>
        </div>


      ) : null}





    </>
  )


}

export default ShowDQMdetails;