import * as Constants from "../../constants";
import axios from "axios";

let useMule = true;
// export async function getOkta(){
//   const response = await fetch(Constants.GETOKTA, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: "Bearer my-token",
//       "My-Custom-Header": "foobar",
//     },
//   });
//   return await response.json();
// }
export async function getUserDetails1() {
  try {
    const response = await fetch(
      "https://jsonplaceholder.typicode.com/posts?_limit=1"
    );
    return await response.json();
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getUserDetails(userid) {
  const response = await fetch(Constants.GETUSERDETAILS + userid, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
  });
  return await response.json();
}

export async function getWorkflows1() {
  try {
    const response = await fetch(
      "https://jsonplaceholder.typicode.com/posts?_limit=1"
    );
    return await response.json();
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getPartners() {
  try {
    //const response = await fetch(Constants.PARTNERURL, headers=headers);
    //console.log(response.status)
    const response = await fetch(Constants.PARTNERURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    return await response.json();
  } catch (error) {
    console.error(error);
    // return [];
    return error;
  }
}

export async function fetchStatusView(userid, tabname,partner_name) {
  var body = {
    action: Constants.TABLES,
    data: {
      //id: tabname === "Actions" ? userid : "pbaner05",
      id: userid,
      role_name: localStorage.getItem("role"),
      tab: tabname,
      email: userid+"@amgen.com",
      partner_name: partner_name,
    },
  };

  const response = await axios(Constants.MULEURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      'x-access-token': window.localStorage.getItem("accessToken"),
    },
    // body: JSON.stringify({ user_name: userid, role_name: localStorage.getItem("role") })

    data: JSON.stringify(body),
  });
  return  response;
}
export async function fetchTemplateStatusView(userid) {
  // const response = await fetch(Constants.TEMPLATESTATUSVIEW, {
  //     method: 'GET',
  //     headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer my-token',
  //         'My-Custom-Header': 'foobar'
  //     },
  // })

  const response = await fetch(Constants.TABLES, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    // body: JSON.stringify({ user_name: userid, role_name: localStorage.getItem("role") })

    body: JSON.stringify({
      id: userid,
      role_name: localStorage.getItem("role"),
      tab: "Template Status",
      email: userid + "@amgen.com",
      role_id: "",
      partner_name: "AMGEN",
      partner_id: "string",
    }),
  });
  return await response.json();
}
export async function fetchTemplateStatusView1(userid) {
  // console.log(partnername)
  const response = await fetch(Constants.TEMPLATESTATUSVIEW, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify({
      userid: userid,
      role: localStorage.getItem("role"),
    }),
  });
  return await response.json();
}

export async function fetchPartnerStatusView(userid) {
  const response = await fetch(Constants.TABLES, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify({
      id: userid,
      role_name: localStorage.getItem("role"),
      tab: "Partner Status",
      email: userid + "@amgen.com",
      role_id: "",
      partner_name: "AMGEN",
      partner_id: "string",
    }),
  });
  return await response.json();
}

export async function fetchPartnerAction(userid) {
  const response = await fetch(Constants.TABLES, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify({
      id: userid,
      role_name: localStorage.getItem("role"),
      tab: "Partner Actions",
      email: userid + "@amgen.com",
      role_id: "",
      partner_name: "AMGEN",
      partner_id: "string",
    }),
  });
  return await response.json();
}

export async function partnerStatusUpdate(payload) {
  //console.log(JSON.stringify( {request_list:payload}))
  const response = await fetch(Constants.PARTNERSTATUSUPDATE, {
    //credentials: 'include',
    method: "POST",
    // dataType: 'JSON',
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ request_list: payload }),
  });
  return await response.json();
}

export async function templateStatusUpdate(payload) {
  // console.log(
  //   JSON.stringify({
  //     request_list: payload,
  //     role: localStorage.getItem("role"),
  //   })
  // );
  const response = await fetch(Constants.TEMPLATESTATUSUPDATE, {
    //credentials: 'include',
    method: "POST",
    // dataType: 'JSON',
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      request_list: payload,
      role: localStorage.getItem("role"),
    }),
  });
  return await response.json();
}

export async function statusUpdate(payload, approvalStatus) {
  var body = {
    action:
      approvalStatus === "APPROVED" || approvalStatus === "REJECTED"
        ? Constants.STATUSUPDATE
        : Constants.ARCHIVEUPDATE,
    data: payload,
  };
  const response = await axios(Constants.MULEURL, {
    //credentials: 'include',
    method: "POST",
    // dataType: 'JSON',
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      'x-access-token': window.localStorage.getItem("accessToken"),
    },
    data: JSON.stringify(body),
  });
  return response;
}
export async function fetchPartnerLinkTemplate(reqid) {
  //console.log(reqid)
  //reqid = 'REQ1003'
  const response = await fetch(Constants.PARTNERLINKTEMPLATE + reqid, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
  });
  return await response.json();
}

export async function fetchDQMDetails(reqid) {
  //console.log(reqid)
  //reqid = 'REQ1003'
  const response = await fetch(Constants.FETCHDQMDETAILS + reqid, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
  });
  return await response.json();
}

export async function templatesForPartners(partnerid, partnername) {
  // console.log(partnername)
  const response = await fetch(Constants.TEMPLTESFORPARTNERS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify({ partner_id: partnerid, partner_name: partnername }),
  });
  return await response.json();
}

export async function getUserRoles() {
  // console.log(partnername)
  const response = await fetch(`https://reqres.in/api/posts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    //body: JSON.stringify({username: username})
  });
  return await response.json();
}

export async function getTemplatestatus(templatename) {
  // try{
  //     const response = await fetch(Constants.GETTEMPLATESTATUS+templatename);
  //     return await response.json();

  // }catch(error) {
  //     console.error(error);
  //    // return [];
  //    return error;
  // }
  const response = await fetch(Constants.GETTEMPLATESTATUS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
    },
    body: JSON.stringify({ name: templatename }),
  });
  return await response.json();
}

export async function checkTemplateApproval(templatename) {
  // try{
  //     const response = await fetch(Constants.GETTEMPLATEAPPROVAL+templatename);
  //     return await response.json();

  // }catch(error) {
  //     console.error(error);
  //    // return [];
  //    return error;
  // }

  const response = await fetch(Constants.GETTEMPLATEAPPROVAL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
    },
    body: JSON.stringify({ name: templatename }),
  });
  return await response.json();
}

export async function submitTemplate(formdata) {
  //console.log(JSON.stringify( formdata))
  // console.log(JSON.stringify({ x: 5, y: 6 }));
  // let payloadlatest  =  JSON.stringify( payload)

  const response = await fetch(Constants.SUBMITTEMPLATEAPI, {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      //  'Content-Type': 'application/json',
      //  'charset': 'utf-8',
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    //body: JSON.stringify( payload)
    body: formdata,
  });
  //console.log(response)
  return await response.json();
}

export async function submitPartner(payload) {
  //console.log(payload)
  const response = await fetch(Constants.SUBMITPARTNERAPI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify(payload),
    // body: JSON.stringify({
    //     title: title,
    //     body: body,
    //     userId: Math.random().toString(36).slice(2),
    //  }),
  });
  return await response.json();
}

export async function getTemplate(templatename) {
  //console.log(templatename)
  //  const response = await fetch(Constants.GETTEMPLATEDETAILS+templatename, {
  //      method: 'GET',
  //      headers: {
  //          'Content-Type': 'application/json',
  //          }

  //    })
  //  return await response.json();
  const response = await fetch(Constants.GETTEMPLATEDETAILS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
    },
    body: JSON.stringify({ name: templatename }),
  });
  return await response.json();
}
export async function getTemplateAll() {
  //console.log(templatename)
  const response = await fetch(Constants.GETTEMPLATEALL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await response.json();
}

export async function fetchTemplateMatchingColumnnameApi(templatename) {
  //console.log(templatename)
  const response = await fetch(Constants.GETTEMPLATETABLE + templatename, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await response.json();
}

export async function downloadtemplare(templatename) {
  //console.log(templatename)
  const response = await fetch(Constants.DOWNLOADTEMPLATE + templatename, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await response;
}

export async function fetchTemplateAction1(userid) {
  const response = await fetch(Constants.FETCHTEMPLATEACTION + userid, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
  });
  return await response.json();
}

export async function fetchTemplateAction(userid) {
  // console.log(partnername)
  const response = await fetch(Constants.FETCHTEMPLATEACTION, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify({
      user_name: userid,
      role_name: localStorage.getItem("role"),
    }),
  });
  return await response.json();
}
// export async function fetchUserDetails(userid) {
//   // console.log('action hello',userid)
//   const response = await fetch(Constants.GETUSERDETAILSAPI, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: "Bearer my-token",
//       "My-Custom-Header": "foobar",
//     },
//     body: JSON.stringify({ id: userid }),
//   });
//   return await response.json();
// }
export async function fetchUserDetails(userid) {
  var data = {
    action: Constants.GETUSERDETAILSAPI,
    data: {
      id: userid,
    },
  };
  if (useMule) {
    const response = await axios({
      method: "POST",
      data: data,
      url: Constants.MULEURL,
      headers: {
        "Content-Type": "application/json",
        'x-access-token': window.localStorage.getItem("accessToken"),
        //'x-access-token': Constants.xaccesstoken,
      },
    });
    return response;
  } else {
    const response = await fetch(
      Constants.dummyURL + "/" + Constants.GETUSERDETAILSAPI,
      {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
          "My-Custom-Header": "foobar",
          'x-access-token': window.localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({ id: userid }),
      }
    );
    return await response.json();
  }
  // console.log('action hello',userid)
  // const response = await fetch(Constants.MULEURL, {
  //   method: "POST",

  // headers: {
  //   "Content-Type": "application/json",
  //   Authorization: "Bearer my-token",
  //   "My-Custom-Header": "foobar",
  // },
  // body: JSON.stringify({ id: userid }),

  // });
  // return await response.json();
}
export async function fetchUserRoles(userRole) {
  // console.log('action hello',userRole)
  var body = {
    action: Constants.GETUSERROLESAPI,
    headers: {
      "Content-Type": "application/json",
      'x-access-token': window.localStorage.getItem("accessToken"),
      //'x-access-token': Constants.xaccesstoken,
    },
    data: {
      id: userRole.user_id,
      role: userRole.role_id,
      partner: userRole.partner_id,
    },
  };

  if (useMule) {
    const response = await fetch(Constants.MULEURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
        "My-Custom-Header": "foobar",
        'x-access-token': window.localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } else {
    const response = await fetch(
      Constants.dummyURL + "/" + Constants.GETUSERROLESAPI,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
          "My-Custom-Header": "foobar",
        },
        body: JSON.stringify({
          id: userRole.user_id,
          role: userRole.role_id,
          partner: userRole.partner_id,
        }),
      }
    );
    return await response.json();
  }
}
export async function fetchUploadTemplate(userRole) {
  // console.log('action hello',userRole)
  let bodyObj = {
    action: Constants.GETUPLOADTEMPLATE,
    data: {
      id: userRole.user_id,
      role: userRole.role_id,
      partner: userRole.partner_id,
    },
  };
  const response = await fetch(Constants.MULEURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
     'x-access-token': window.localStorage.getItem("accessToken"),
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify(bodyObj),
  });
  return await response.json();
}
export async function fetchUploadWorkflow(uploadTemplate) {
  // console.log('action hello',userRole)
  let bodyObj = {
    action: Constants.GETUPLOADWORKFLOW,
    data: {
      name: uploadTemplate,
    },
  };
  const response = await fetch(Constants.MULEURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      'x-access-token': window.localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(bodyObj),
  });
  return await response.json();
}
export async function uploadExcelFile(formData) {
  const response = await fetch(Constants.UPLOADEXCELFILE, {
    method: "POST",
    headers: {
     
      Authorization: "Bearer "+window.localStorage.getItem("hashToken"),
      'x-access-token': window.localStorage.getItem("accessToken"),
      // 'x-access-token': Constants.xaccesstoken,
      // Authorization: Constants.Authorizationbearer,
      
    },
    body: formData,
  });
  return await response.json();
}
export async function validateUser() {
  

  const response = await axios({
    method: "POST",
    
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer "+window.localStorage.getItem("hashToken"),
      'x-access-token': window.localStorage.getItem("accessToken"),
      // 'x-access-token': 'eyJraWQiOiJsMVgwd1BiUklTQWMtVmN0cmxpYXFPbXNLaVowTHRpbGNKV2szUVVVRWVNIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULm5iT2hFRkd5aENZLVAzX1otR0RFS1d3bS0zRE44R2F2VU1ES2VqRHhIWVkiLCJpc3MiOiJodHRwczovL2FtZ2VudGVzdC5va3RhcHJldmlldy5jb20vb2F1dGgyL2RlZmF1bHQiLCJhdWQiOiJhcGk6Ly9kZWZhdWx0IiwiaWF0IjoxNzI3MTA1OTQ3LCJleHAiOjE3MjcxMDk1NDcsImNpZCI6IjBvYTI0cDhzNGRkRVBlSllEMGg4IiwidWlkIjoiMDB1YzR4dnE0anZjbnBiV3AwaDciLCJzY3AiOlsiZW1haWwiLCJvcGVuaWQiLCJwcm9maWxlIl0sImF1dGhfdGltZSI6MTcyNzEwNTk0Niwic3ViIjoic3NlbjAzQGFtZ2VuLmNvbSIsIkdyb3VwcyI6WyJjYXVzYWwtc3R1ZGlvIl0sIkVtcGxveWVlTnVtYmVyIjoiMjE1MDUzIn0.U90ufbNABq8UkefTdk569Pq_MOsUXJ4mebOFcBnBicOsdWg0iNO4MzGKxc098JSJ7yWNd78wDngZghDF56d1D8qdNbXgyXvgGCq9QNsF7jLORCIA7a3SLEAQ4eQqSF985aee3uYrJpq2hAyZ3fGxSweGeb-qwSi_wlYGEIO4CRVWbzU200ULXe-nRxYdr0kAnV2MhCZh-h519K-g4tCzNOmDI0peGkPo5v913z1sji5GU66PmV9rlwEtjLIzGv0jHxtidh1DHsrFpuCRzi307pZpOvZFlZ8or8R6tmT0eD_CZbQacdKSTICN3MNuFh_ywBkBtijIHATpDMthntEsvQ',
      // Authorization: "Bearer eyJraWQiOiJsMVgwd1BiUklTQWMtVmN0cmxpYXFPbXNLaVowTHRpbGNKV2szUVVVRWVNIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHVjNHh2cTRqdmNucGJXcDBoNyIsIm5hbWUiOiJTZW4sIFN1ZGV2IiwiZW1haWwiOiJzc2VuMDNAYW1nZW4uY29tIiwidmVyIjoxLCJpc3MiOiJodHRwczovL2FtZ2VudGVzdC5va3RhcHJldmlldy5jb20vb2F1dGgyL2RlZmF1bHQiLCJhdWQiOiIwb2EyNHA4czRkZEVQZUpZRDBoOCIsImlhdCI6MTcyNzEwNTk0NywiZXhwIjoxNzI3MTA5NTQ3LCJqdGkiOiJJRC5GNXJkdVJlQUR3SHBxTkdDcGs4NmxwR1NQUWpJNVd2RVhVczlFemRSQ3hrIiwiYW1yIjpbInB3ZCJdLCJpZHAiOiIwMG81NzdxMWNiUGNzMGRvZDBoNyIsIm5vbmNlIjoibEdQTkhvSzZZZVljYkQ1TXJtamVBdyIsInByZWZlcnJlZF91c2VybmFtZSI6InNzZW4wM0BhbWdlbi5jb20iLCJhdXRoX3RpbWUiOjE3MjcxMDU5NDYsImF0X2hhc2giOiJXVng1N3FqWWZTTW9GNnVnLTZQemlRIiwiY19oYXNoIjoib2toVHJRaWM2MDlFTUlYeHVfSmM5dyIsInVzZXJfZW1haWwiOiJzc2VuMDNAYW1nZW4uY29tIiwiTG9naW5JRCI6InNzZW4wMyIsInN0YWZmX2lkIjoiMjE1MDUzIiwiVXNlcl9JRCI6InNzZW4wMyJ9.KpJ1wuicfGYIkk_KS__T9CMLw_HV2M4HKKnc_gv_xCY-4k_D_K_L5oUFnF9puoLTK5lKW5J6IbAtHGdG8gyo0fL7K3HW9q5GDKEiJffzgK0Gy-v1TtvLjLwdr86Cz0EM8rMwvec8t6rdz-x161KGRcj9xpq7Fsq3ETUPjP-9RdfegXxj-WfUl5ksai0qG2npUrw6P_gpTODBlaDxWSYQFSnjSMjgViQzc8D6MGqOHL71igfclQUKjcUPncFs5w1OeJ7HnBp_zx8R_c0eGHrQRk_STnG9hJGKmXUPMYpxrZrg1sSbMmuI42OgYxyo36t8TiVxOezV7EqgUAsGvjaV0Q",

    },
    url: Constants.VALIDATEUSER,
    data: JSON.stringify({}),
  });
  return response;
}
export async function fetchPartnerRoleList() {
  let bodyObj = {
    action: Constants.GETPARTNERROLELIST,
    data: {},
  };
  const response = await fetch(Constants.MULEURL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      'x-access-token': window.localStorage.getItem("accessToken"),
    },
    method: "POST",
    body: JSON.stringify(bodyObj),
  });
  return await response.json();
}
export async function inviteNewUser(invitedUserObj) {
  //console.log("invitedUserObjStringify", JSON.stringify(invitedUserObj));
  //console.log("invitedUserObj", invitedUserObj);
  let bodyObj = {
    action: Constants.INVITENEWUSER,
    data: invitedUserObj,
  };
  const response = await fetch(Constants.MULEURL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      'x-access-token': window.localStorage.getItem("accessToken"),
    },
    method: "POST",
    body: JSON.stringify(bodyObj),
  });
  return await response.json();
}

// export async function downloadTemplate(downloadTemplateName) {

//     const response = await fetch(Constants.DOWNLOADTEMPLATERESOURCES+'?file='+downloadTemplateName, {
//     //   headers: {
//     //     "Content-Type": "application/json",
//     //     Authorization: "Bearer my-token",
//     //     "My-Custom-Header": "foobar",
//     //   },
//       responseType: 'blob',
//     // responseType: 'arraybuffer',
//       method: "GET",
//     //   body: JSON.stringify(downloadTemplateObj),
//     });
//     return await response.json;
//   }

export const downloadTemplate = async (downloadTemplateName) => {
  // let oktaToken = sessionStorage.getItem("dib_token");

  let payloadObj = {
    action: Constants.DOWNLOADRESOURCE,
    data: {
      fileName: downloadTemplateName,
      fileType: "xlsx",
    },
  };
  const response = await axios({
    method: "POST",
    responseType: "blob",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      //Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      Authorization: "Bearer "+window.localStorage.getItem("hashToken"),
      'x-access-token': window.localStorage.getItem("accessToken"),
      // 'x-access-token': Constants.xaccesstoken,
      // Authorization: Constants.Authorizationbearer,
      
    },
    url: Constants.DOWNLOADPDFRESOURCES,
    data: JSON.stringify(payloadObj),
  });
  return response;
};

export const downloadResourcesTemplate = async (pdfName) => {
  // let oktaToken = sessionStorage.getItem("dib_token");
  //console.log('pdfName',pdfName);
  let payloadObj = {
    action: Constants.DOWNLOADRESOURCE,
    data: {
      fileName: pdfName,
      fileType: "pdf",
    },
  };
  const response = await axios({
    method: "POST",
    responseType: "blob",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      "My-Custom-Header": "foobar",
      
      Authorization: "Bearer "+window.localStorage.getItem("hashToken"),
      'x-access-token': window.localStorage.getItem("accessToken"),
      // 'x-access-token': Constants.xaccesstoken,
      // Authorization: Constants.Authorizationbearer,

    },
    url: Constants.DOWNLOADPDFRESOURCES,
    data: JSON.stringify(payloadObj),
  });
  return response;
};

export const getResourceVideoLink = async (videoName) => {
  // let oktaToken = sessionStorage.getItem("dib_token"); baseMuleURL
  let payloadObj = {
    action: Constants.DOWNLOADRESOURCE,
    data: {
      fileName: videoName,
      fileType: "mp4",
    },
  };
  const response = await axios({
    method: "POST",
    // responseType: 'blob',
    headers: {
      //  accept: "application/json",
      "Content-Type": "application/json",
      // 'x-access-token': Constants.xaccesstoken,
      // Authorization: Constants.Authorizationbearer,
      Authorization: "Bearer "+window.localStorage.getItem("hashToken"),
      'x-access-token': window.localStorage.getItem("accessToken"),
      "My-Custom-Header": "foobar",
    },

    url: Constants.DOWNLOADPDFRESOURCES,
    data: JSON.stringify(payloadObj),
  });
  //console.log('response',response);
  return response;
};

//   export async function downloadFile118(downloadfilepayload) {

// var body ={"action":"downloadFiles","data":downloadfilepayload}
//     const response = await fetch(Constants.DOWNLOADFILEACTION, {
//         method: 'POST',
//         responseType: 'blob',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': 'Bearer my-token',
//             'My-Custom-Header': 'foobar'
//         },
//         body: JSON.stringify(body),

//     })
//     return  response;

// }

export const downloadFile11 = async (downloadfilepayload) => {
  const response = await axios({
    method: "POST",
    responseType: "blob", // to indicate that the server will send a Blob
    data: downloadfilepayload,
    url: Constants.DOWNLOADFILEACTION,
  });
  return response;
};
export const downloadFile = async (downloadfilepayload) => {
  var data = {
    action: Constants.DOWNLOADFILE,
    //"data": JSON.stringify(downloadfilepayload)
    data: downloadfilepayload,
  };
  const response = await axios({
    method: "POST",
    headers: {
      //  accept: "application/json",
      "Content-Type": "application/json",
      // 'x-access-token': Constants.xaccesstoken,
      // Authorization: Constants.Authorizationbearer,
      Authorization: "Bearer "+window.localStorage.getItem("hashToken"),
      'x-access-token': window.localStorage.getItem("accessToken"),
      "My-Custom-Header": "foobar",
    },
    responseType: "blob", // to indicate that the server will send a Blob
    data: data,
    url: Constants.DOWNLOADFILEACTION,
  });
  //console.log(response)
  if(response.status===200){
    return response;
  }
  else
  {
    return response;
  }
  
};

export async function fetchFileDetails(actionid, type) {
  // console.log('action hello',userRole)

  var body = {
    action: Constants.FETCHFILEDETAILS,
    data: {
      actionId: actionid,
      actionType: type,
    },
  };
  const response = await axios(Constants.MULEURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      'x-access-token': window.localStorage.getItem("accessToken"),
    },
    data: JSON.stringify(body),
  });
  return  response;
}

export async function fetchBasicFilter(tempid, tempname) {
  // console.log('action hello',userRole)
  const user = JSON.parse(localStorage.getItem("userDetails"));
  
  var body = {
    action: Constants.FETCHBASICFILTER,
    data: {
      callFor: "initial",
      partnerName: user.partner_name,
      tabType: "filterPage",
      templateID: tempid.toString(),
      templateName: tempname,
    },
  };
  const response = await axios(Constants.MULEURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      'x-access-token': window.localStorage.getItem("accessToken"),
    },
    data: JSON.stringify(body),
  });
  //return await response.json();
 return response
}

export async function submitBasicFilter(payload) {
  // console.log('action hello',userRole)

  var body = {
    action: Constants.FETCHBASICFILTER,
    data: payload,
  };
  //console.log("payload", body);
  const response = await axios(Constants.MULEURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      'x-access-token': window.localStorage.getItem("accessToken"),
    },
    data: JSON.stringify(body),
  });
  //console.log(response);
  //return await response.json();
  return response
}
export async function submitAdvanceFilter(payload) {
  // console.log('action hello',userRole)

  var body = {
    action: Constants.FETCHADVANCEFILTER,
    data: payload,
  };
  //console.log("payload", body);
  const response = await axios(Constants.MULEURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      'x-access-token': window.localStorage.getItem("accessToken"),
    },
    data: JSON.stringify(body),
  });
  //console.log(response);
  return response
 // return await response.json();
}

export async function setSOPFLag(userid) {
  var data = {
    action: Constants.SETSOPFLAG,
    data: {
      id: userid,
    },
  };
  if (useMule) {
    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'x-access-token': window.localStorage.getItem("accessToken"),
        //'x-access-token': Constants.xaccesstoken,
      },
      data: data,
      url: Constants.MULEURL,
    });
    return response;
  } else {
    const response = await fetch(
      Constants.dummyURL + "/" + Constants.SETSOPFLAG,
      {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
          "My-Custom-Header": "foobar",
          'x-access-token': window.localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({ id: userid }),
      }
    );
    return await response.json();
  }
}

export async function fetchEsignURL() {
  // console.log('action hello',userRole)

  // console.log("payload", body);
  const response = await fetch(Constants.FETCHESIGNURL, {
    method: "GET",
   
  });
  // console.log(response)
  return await response.json();
}


export function postChatBot(paramsData) {
  //console.log("chatbotparam", paramsData);
  //   let hardCodedResp={
  //     "choices": [
  //         {
  //             "index": 0,
  //             "message": {
  //                 "role": "assistant",
  //                 "content": "Hello! I'm happy to help you with any questions you may have. Please go ahead and ask your question, and I'll do my best to provide a helpful response."
  //             },

  //         }
  //     ]
  // }
  return new Promise(async (resolve, reject) => {
    try {
      let response = await postData(Constants.MULEURL, paramsData.message);
      // let response=hardCodedResp;
      if (response.hasOwnProperty("error_code")) {
        resolve({
          status: false,
          message: response.error_message,
          data: response.error_message,
        });
      } else {
        if (response ) {
          resolve({ status: true, message: "success", data: [
            {
                "index": 0,
                "message": {
                    "role": "assistant",
                    "content": response
                },
               
            }
        ]
     });
        }
      }
    } catch (error) {
      resolve({ status: false, message: "error: api call", data: error });
    }
  });
}
function postData(url, data) {
  let bodyObj = {
    action: Constants.POSTCHATBOT,
    data: {
      question: data,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyObj),
      });
      if (!response.ok) {
        // toast("Error: Posting Data to API", { type: 'error', autoClose: 1000 });
        throw new Error("Failed to post data to API");
      }
      const responseData = await response.json();
      resolve(responseData);
    } catch (error) {
      console.error("Error posting data to API:", error);
      reject(error);
    }
  });
}




