

import React, {
    useState,
    useContext,
    useRef,
    useEffect,
    useMemo,
    useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// import "@ag-grid-community/styles/ag-theme-quartz.css";
import ShowDQMdetails from './ShowDQMdetails';
import Accounttemplatestyle from '../Template/Addtemplate.module.css';
import './FileApprovalStatus.css';
import {status_colors} from "../../constants";
import { Link } from "react-router-dom";
import { CheckLg, Download, Eye, X } from 'react-bootstrap-icons';
import ShowfileDetails from "./ShowfileDetails";
import { fetchStatusView } from "../Services/UserServices";
import userContext from "../../user-context"
const FileApprovalAction = (props) => {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const userdetailsokta = useContext(userContext);
    const [errormessage, setErrormessage] = useState("");
    const [successmessage, setSuccessmessage] = useState("");
    const [selectedata, setSeleteddata] = useState({});
    const [showDQM, setshowDQM] = useState(false);
    const [selecterowclickdata, setSeletedrowclickdata] = useState([]);
    const [checkboxselect, setCheckboxselect] = useState(false);
    const [errorstate, setErrorcheck] = useState(false);
    const [modalAppralOpen, setModalAppralOpen] = useState(false)
    const [show, setShowApprovalModal] = useState(false)
    const [approvalStatus, setApprovalStatus] = useState("")

    const [showfile, setviewFileDetailsModal] = useState(false)
    useEffect(() => {
        window.addEventListener("error", (e) => {
            if (e.message === "ResizeObserver loop limit exceeded") {
                const resizeObserverErrDiv = document.getElementById(
                    "webpack-dev-server-client-overlay-div"
                );
                const resizeObserverErr = document.getElementById(
                    "webpack-dev-server-client-overlay"
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute("style", "display: none");
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute("style", "display: none");
                }
            }
        });

    }, []);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,

            sortable: true,
            color: 'red',

            wrapHeaderText: true,
            suppressMovable: true,
            autoHeaderHeight: true,
            //     wrapText: true,
            //    autoHeight: true,
            unSortIcon: true,
        };
    }, []);
    const [columnDefs] = useState([
        {
            headerName: "File Name",
            field: "fileName",
            tooltipField: "fileName",
            checkboxSelection: true,
            //width: 50,
            // maxWidth: 330,
            width: 100,
            maxWidth: 210,
            //wrapText: true, autoHeight: true,
            cellRenderer: function (params) {
                //console.log(params)
                return <><a href="#" style={{ cursor: 'pointer', color: '#181d1f', textDecoration: 'underline' }} data-toggle="tooltip"
                    onClick={() => viewFileDetails(params)} data-placement="top"
                    title={params.value} >{params.value}</a>

                </>;
            }
        },
        {
            headerName: "File Ver.",
            field: "file_version",
            tooltipField: "file_version",
            width: 70, maxWidth: 92,
        },
        {
            headerName: "Template Name",
            field: "templateName",
            tooltipField: "templateName",
            width: 10,
            maxWidth: 180,
        },
        {
            headerName: "Author",
            field: "author",
            tooltipField: "author",
            width: 80, maxWidth: 148,
        },
        {
            headerName: "Approval Status",
            field: "status",
            tooltipField: "status",
            width: 10,
            maxWidth: 250,
            //cellRenderer: CustomButtonComponent,
            //wrapText: true, autoHeight: true,

            cellRenderer: function (params) {
                //console.log(params)
                return (<button className="btn btn-primary" style={{ color:status_colors[params.value], border: 'none', fontSize: '13px', backgroundColor: 'rgb(241 196 196)' }}>{params.value}</button>);
                // if (params.value === "Stage 1 Pending") {

                //     return (<button className="btn btn-primary" style={{ color: 'red', border: 'none', fontSize: '13px', backgroundColor: 'rgb(241 196 196)' }}>{params.value}</button>);
                //     // return ( <div style={{ color: 'red', backgroundColor: 'rgb(241 196 196)',borderRadius:'5px',paddingTop:'10px',height:'42px'}}><span >{params.value}
                //     // </span></div>);
                // }
                // else if (params.value === "File Conversion Passed") {
                //     return (<button className="btn btn-primary" style={{ color: '#156391', border: 'none', fontSize: '13px', backgroundColor: '#94c9e9' }}>{params.value}</button>);
                //     // return ( <div style={{ color: '#156391', backgroundColor: 'rgb(155 197 227)',height:'42px',borderRadius:'5px'}}><span >{params.value}
                //     // </span></div>);

                // }
                // else if (params.value === "Data Quality Check awaiting") {

                //     return (<button className="btn btn-primary" style={{ color: 'black', border: 'none', fontSize: '13px', backgroundColor: 'rgb(239 234 116)' }}>{params.value}</button>);

                // }
                // else {
                //     return <span>{params.value}</span>;
                // }

            },
            // cellStyle: function (params) {
            //     // console.log(params.value);

            //     //return <span className={params.value==='FAILED'?'text-danger':''}>{params.value}</span>
            //     if (params.value === "Stage 1 pending") {
            //         return { color: 'red', backgroundColor: '#e17c7c',marginTop:'10px' };
            //     }
            //     else if (params.value === "File Conversion Passed") {
            //         return { color: 'blue', backgroundColor: '#5ba4d9' };
            //     }
            //     else if (params.value === "Data Quality Check awaiting") {
            //         return { color: 'black', backgroundColor: '#cb969b' };
            //     }
            //     else {
            //         return <span>{params.value}</span>;
            //     }
            // },

        },
        {
            headerName: 'Last Modified Date', field: 'modifiedDate',
            width: 130, maxWidth: 160,
            tooltipField: 'modifiedDate'
        },
        {
            headerName: "Stage 1 Approver",
            field: "stage1Approver",
            tooltipField: "stage1Approver",
            width: 80, maxWidth: 140,
        },
        {
            headerName: "Stage 2 Approver",
            field: "stage2Approver",
            tooltipField: "stage2Approver",
            width: 80, maxWidth: 140,
        },

        {
            headerName: "Action",
            field: "view",
            width: 80, maxWidth: 110,
            cellRenderer: function (params) {
                //console.log(params)
                return <><span style={{ cursor: 'pointer' }} data-toggle="tooltip" onClick={() => viewData(params)} data-action-type="view" data-placement="top" title="View DQM" ><Eye size={20} data-action-type="view" /></span>
                    <span className='pl-2' style={{ cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Download"><Download size={18} data-action-type="download" /></span>

                </>;
            }
        },


    ])
    const onGridReady = useCallback((params) => {
        fetchStatusViewData()
        // const data = [
        //     { filename: "DPC_AMG510_acryloylchloridedistillation_Copy", fileversion: "1",templatename:"DPC_AMG510_acryloylchlor", author: 'Chayanika Halder', status: 'Stage 1 Pending', stage1approver: 'chalde01', stage2approver: 'chalde01', comments: 'hiii' },

        //     { filename: "AMG2309_AUDITCHECK_AMGEN_DPC_MES_QC.xlsx",approvedDt:"2023-08-23 06:53:37", fileversion: "1",templatename:"DPC_MES_QC", author: 'Chayanika Halder', status: 'Stage 1 Pending', stage1approver: 'Abhisikta Bhattacharjee', stage2approver: 'Abhisikta Bhattacharjee', comments: 'hiii' },
        //     { filename: "AMG2309_AUDITCHECK_AMGEN_DPC_MES_QC.xlsx",approvedDt:"2023-08-23 06:53:37", fileversion: "1",templatename:"DPC_MES_QC", author: 'Chayanika Halder', status: 'Stage 1 Pending', stage1approver: 'Abhisikta Bhattacharjee', stage2approver: 'Abhisikta Bhattacharjee', comments: 'hiii' },
        //     { filename: "AMG2309_AUDITCHECK_AMGEN_DPC_MES_QC.xlsx",approvedDt:"2023-08-23 06:53:37", fileversion: "1",templatename:"DPC_MES_QC", author: 'Chayanika Halder', status: 'Stage 1 Pending', stage1approver: 'Abhisikta Bhattacharjee', stage2approver: 'Abhisikta Bhattacharjee', comments: 'hiii' },
        //     { filename: "AMG2309_AUDITCHECK_AMGEN_DPC_MES_QC.xlsx",approvedDt:"2023-08-23 06:53:37", fileversion: "1",templatename:"DPC_MES_QC", author: 'Chayanika Halder', status: 'Stage 1 Pending', stage1approver: 'Abhisikta Bhattacharjee', stage2approver: 'Abhisikta Bhattacharjee', comments: 'hiii' },

        // ];

        // setRowData(data);
    }, []);
    const gridOptions = {
        columnDefs: columnDefs,
        defaultColDef: defaultColDef,
        rowData: rowData,
        cacheQuickFilter: true,

    };
    const fetchStatusViewData = () => {

        fetchStatusView(userdetailsokta.userID, 'Actions').then((datas) => {

            var temphistorydata = datas;
            setRowData(temphistorydata);
            //console.log(temphistorydata)
        })
            .catch(error => {
                setErrormessage(error.toString())
                //console.error('There was an error!', error);

            });
    }

    const onFilterTextBoxChanged = useCallback(() => {
        // gridOptions.api.setQuickFilter(
        //     document.getElementById('filter-text-box').value
        // );
        gridRef.current.api.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, []);

    function viewData(data) {
        //console.log(data)
        // var requestid = data.requestId
        //console.log(requestid)
        setSeleteddata(data['data'])
        setshowDQM(showDQM => !showDQM)
        //return (<ShowDQMdetails dqmdata = {selectedata} showtem={showDQM} />);
    }

    const onSelectionChanged = useCallback((event) => {
        //var selectedRows = gridRef.current.api.getSelectedRows();
        // console.log(gridRef.current.api.getSelectedRows())
        // console.log(gridOptions.api.getColumnDefs())
        // if ('getSelectedRows' in gridOptions.api)
        // {
            var selectedRows =gridRef.current.api.getSelectedRows();
            //console.log(gridRef.current.api.getColumnDefs())
            var getcoldef = gridRef.current.api.getColumnDefs();
            //console.log(getcoldef)
            var selectedRowsString = '';
            var maxToShow = 5;
            var selectedname = []
            selectedRows.forEach(function (selectedRow, index) {
                if (index >= maxToShow) {
                    return;
                }
                if (index > 0) {
                    selectedRowsString += ', ';
                }
                selectedRowsString += selectedRow['filename'];
                selectedname.push(selectedRow)
            });
            setSeletedrowclickdata(selectedname)
            //console.log((selectedRows))
    
            //********************************* */
            gridRef.current.api.setColumnDefs(getcoldef)
      //  }

       
    
}, []);

function errorClass(error) {
    return (error ? 'is-invalid' : '');
}

let selectedTemplate = (type) => {
    setApprovalStatus('APPROVED')
    //console.log(selecterowclickdata)
    //console.log(document.getElementsByClassName('comments_textarea')[0].value)
    //console.log(checkboxselect)
    if (selecterowclickdata.length === 0) {
        setErrormessage("Please select at least one checkbox")
        return false
    }
    else {
        setErrormessage("")
        let status = ''
        if (type === 'approve') {
            status = 'APPROVED'
            // console.log(document.getElementsByClassName('check_lock')[0].value)
            if (checkboxselect === false) {
                setErrorcheck(true)
                return false

            }
            else {
                setErrorcheck(false)
                setModalAppralOpen(true)
                setShowApprovalModal(true)
            }

        }
        else {
            status = 'REJECTED'

            var actiontemplate = []

            //     for (let row = 0;row<selecterowclickdata.length;row++)
            //     {
            //         actiontemplate.push({'requestID':selecterowclickdata[row]['requestId'],'currentStatus':status,
            //             'comments':document.getElementsByClassName('comments_textarea')[0].value,
            //             'stageOneApprovedBy': (localStorage.getItem('role') === 'Stage 1 Template Approver')?userdetailsokta.userID:selecterowclickdata[row]['stage_One_Approver'],
            //         'adminPortalApprovedBy':(localStorage.getItem('role') === 'Stage 1 Template Approver')?'':userdetailsokta.userID
            //             })
            //     }
            //    // console.log(actiontemplate)

            //     templateStatusUpdate(actiontemplate).then((datas) => 
            //     {
            //         //console.log(datas)
            //         if(datas === true)
            //         {
            //             fetchTemplateActionData()
            //             props.parentTabCallback(0)
            //             document.getElementsByClassName('comments_textarea')[0].value = '';
            //             setErrormessage('')
            //             setErrorcheck(false)
            //             document.getElementsByClassName("check_lock")[0].checked = false
            //             setSuccessmessage("The request has been "+status)
            //         }


            //     })
            //     .catch(error => {
            //         setSuccessmessage("")
            //         setErrormessage(error.toString())
            //         setErrorcheck(false)
            //         setModalAppralOpen(false)
            //         document.getElementsByClassName("check_lock")[0].checked = false
            //         console.error('There was an error!', error);

            //     });

        }



    }

}

let handleCloseApproval = () => {
    setShowApprovalModal(false)
    // setShow(false);
};
let handleShowApproval = () => {
    setShowApprovalModal(true)
}


function viewFileDetails(data) {
    //console.log(data.data)
    setviewFileDetailsModal(true)
    setSeleteddata(data['data'])
}
return (
    <div className='row approvalbackground'>
        <div className='col-12'>
            <div className={` mediumtextformat  py-3  mx-auto`} style={{ width: '95%' }}>
                <div className='insideApproval'>
                    <div className='tabsection novborder'>
                        <nav className="navbar navbar-expand-md pb-0" style={{ paddingLeft: '4%' }}>

                            <div className="collapse navbar-collapse " id="navbarNavDropdown">
                                <ul className="navbar-nav">
                                    <li className="nav-item active">
                                        <Link className="nav-link linkdec" to="/action">Action <span className="right-border pr-2"></span></Link>
                                    </li>
                                    <li className="nav-item ">
                                        <Link className="nav-link linkdec" to="/status">Status View</Link>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-8 pb-1 text-center" style={{ minHeight: 30 }}>


                                <div className="input-group searchBox" style={{ width: 'auto' }}>
                                    <div className="input-group-prepend border-right-0">
                                        <div
                                            className="input-group-text bg-transparent "
                                            id="btnGroupAddon2"
                                        >
                                            <i className="fa fa-search font-size-14"></i>
                                        </div>
                                    </div>
                                    <input
                                        className="form-control font-size-14 "
                                        id="filter-text-box"
                                        type="search"
                                        placeholder="Filter..."
                                        onInput={onFilterTextBoxChanged}
                                    />
                                </div>
                            </div>
                        </nav>
                    </div>
                    {showDQM && <ShowDQMdetails dqmdata={selectedata} showtem={showDQM} setshowDQM={setshowDQM} />}
                    {showfile && <ShowfileDetails dqmdata={selectedata} showfile={showfile} setviewFileDetailsModal={setviewFileDetailsModal} />}
                    <div className='pt-1' style={{ paddingLeft: '2%' }}>
                        <div
                            className="agGridWrapper datatablerefresh"
                            style={{ height: "100%", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine datatablerefresh"
                                style={{ height: "66vh", width: "auto", }}
                            >
                                <AgGridReact
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    gridOptions={gridOptions}
                                    onSelectionChanged={onSelectionChanged}
                                    animateRows={true}
                                    cacheQuickFilter={true}
                                    pagination={true}
                                    //paginationPageSize={2}
                                    paginationAutoPageSize={true}
                                    ref={gridRef}
                                    rowSelection={'multiple'}
                                    rowHeight={50}
                                    onGridReady={onGridReady}
                                ></AgGridReact>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ paddingLeft: '2%' }}>
                        <div className="col-sm-4">
                            <div className="form-group pt-1 ">

                                <textarea className="form-control comments_textarea" rows="2" cols="30" placeholder="Comments"></textarea>
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="form-check">
                                <input onClick={(event) => { setCheckboxselect(event.target.checked); }} id="check_lock" className={`form-check-input check_lock ${errorClass(errorstate)}`} type="checkbox" name="check_lock" value="lock" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    <i>Reviewed and verified that the template(s) are locked</i>
                                </label>
                            </div>
                            <div className="form-group">

                                <span className={` ${Accounttemplatestyle.mandetoryfield}`}>{errormessage}</span>
                                <span className="text-success mb-3 globaltextformat ">{successmessage}</span>
                            </div>
                        </div>

                        <div className="col-sm-3">
                            <div className="form-group text-center pt-3 ">

                                <button type="button" onClick={() => selectedTemplate('reject')} className="btn   text-wrap fw-normal submit-reset" id="submit-reset" style={{ margin: '0 10px', backgroundColor: '#ba6868', color: 'white' }}>
                                    <div><span><X size="17" /></span><span>Reject</span></div>
                                </button>


                                <button type="button" onClick={() => selectedTemplate('approve')} className={`btn btn-primary text-wrap fw-normal submit_btnapp`}>
                                    <CheckLg /> Approve
                                </button>

                            </div>
                        </div>
                    </div>

                    {modalAppralOpen &&

                        <div
                            className={"modal fade bd-example-modal-lg" + (show ? " show d-block" : " d-none")}
                            // tabIndex="-1"
                            role="dialog"
                            style={{
                                position: "fixed",
                                top: "0",
                                bottom: "0",
                                left: "0",
                                right: "0",
                                background: "rgb(182 184 202/0.5)",
                                //opacity: "0.5",
                                paddingTop: "12%",
                            }}
                        >
                            <div className="modal-dialog " role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title"></h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseApproval}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        UserId :
                                        <p>Do you want to approve this template?</p>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" onClick={handleShowApproval}>Ok</button>
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleCloseApproval}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }




                </div>

            </div>
        </div>
    </div>

);
}

export default FileApprovalAction;